import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TotalPage from "./pages/Total/TotalPage";
import ApartTotal from "./pages/Apart/ApartTotal";
import ApartProgress from "./pages/Apart/Progress/ApartProgress";
import ApartManequip from "./pages/Apart/Manequip/ApartManequip";
import ApartSafety from "./pages/Apart/Safety/ApartSafety";
import ApartQuality from "./pages/Apart/Quality/ApartQuality";
import ApartCost from "./pages/Apart/Cost/ApartCost";
import ApartPhoto from "./pages/Apart/Photo/ApartPhoto";
import TotalDev from "./pages/Total/TotalDev";
import * as am5 from "@amcharts/amcharts5";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TotalPage />}></Route>
        <Route path="/dev" element={<TotalDev />}></Route>
        <Route path="/:type" element={<TotalPage />}></Route>
        <Route path="/:type/:id/" element={<TotalPage />}></Route>
        <Route path="/:type/:id/:aptid" element={<ApartTotal />}></Route>

        <Route
          path="/:type/:id/:aptid/progress"
          element={<ApartProgress />}
        ></Route>
        <Route path="/:type/:id/:aptid/cost" element={<ApartCost />}></Route>
        <Route
          path="/:type/:id/:aptid/manequip"
          element={<ApartManequip />}
        ></Route>
        <Route
          path="/:type/:id/:aptid/safety"
          element={<ApartSafety />}
        ></Route>
        <Route
          path="/:type/:id/:aptid/quality"
          element={<ApartQuality />}
        ></Route>
        <Route path="/:type/:id/:aptid/photo" element={<ApartPhoto />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
