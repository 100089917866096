import React, { useState } from "react";
import image8 from "../image8.jpg";
import image9 from "../image9.jpg";
import image10 from "../image10.jpg";
import image11 from "../image11.jpg";
import image12 from "../image12.jpg";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const ApartImage2 = ({ height }) => {
  const images = [image8, image9, image10, image11, image12];
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setIsLightboxOpen(true);
    setCurrentImageIndex(index);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      {images.map((image, index) => (
        <div key={index}>
          <img
            src={image}
            alt=""
            height={height * 0.9}
            onClick={() => openLightbox(index)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <p>공사현황</p>
            <p>2023-12-10</p>
          </div>
        </div>
      ))}
      {isLightboxOpen && (
        <Lightbox image={images[currentImageIndex]} onClose={closeLightbox} />
      )}
    </div>
  );
};

export default ApartImage2;
