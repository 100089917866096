import React from "react";
import InfoLine from "../../components/InfoLine";
import { useParams } from "react-router-dom";
import TotalMap2 from "../Total/TotalMap2";
import ImageSwiper from "./ImageSwiper";
import image1 from "./image1.jpg";
import image2 from "./image2.jpeg";
import image3 from "./image3.jpeg";
import image4 from "./image4.jpeg";
const ApartInfo = ({ width, height }) => {
  const { aptid } = useParams();
  const imageList = [
    { image: image3 },
    { image: image4 },
    { image: image2 },
    { image: image1 },
  ];
  return (
    <div>
      <div
        className="card card-flush p-1 me-5 pb-10"
        style={{ minWidth: "380px", height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Project Information</h3>
          </div>
        </div>
        <div className="card-body p-9">
          <div className="mb-10">
            <TotalMap2 />
          </div>
          <div>
            <p>
              <b>Project Summary</b>
            </p>
            <InfoLine title="Project Name" contents={aptid} />
            <InfoLine title="Location" contents="Location" />
            <InfoLine title="Contract Type" contents="Contract Type" />
            <InfoLine title="Project Name" contents="Project1" />
            <InfoLine title="Client" contents="Client" />
          </div>
          <div>
            {/* 
          
            <img
              src={image}
              alt=""
              style={{ width: "300px", height: "200px" }}
            /> */}
            <p>
              <b>현장사진</b>
            </p>
            <ImageSwiper
              width={width - 60}
              height={height / 3 - 60}
              imageList={imageList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartInfo;
