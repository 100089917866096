import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const SafetyBarChart = () => {
  useEffect(() => {
    let root = am5.Root.new("chartdiv1");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);

    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString() +
            " 건";
        }
        i++;
      });
      return text;
    });
    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "months",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 0,
      strokeOpacity: 0.1,
    });

    xRenderer.labels.template.setAll({
      paddingTop: 10,
      scale: 0.8,
      // fill: "#fff",
    });

    xAxis.data.setAll(resultData);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      visible: false,
    });

    // var series1 = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "월간 재해 현황",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "column",
    //     categoryXField: "months",
    //     tooltip: am5.Tooltip.new(root, {
    //       pointerOrientation: "horizontal",
    //       labelText: "{name} in {categoryX}: {valueY} {info}",
    //     }),
    //   })
    // );
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "월간 재해 현황",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "column",
        categoryXField: "months",
        fill: am5.color("#1F3864"),
        color: am5.color("#1F3864"),
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: "horizontal",
        //   labelText: "{name} in {categoryX}: {valueY}",
        // }),
      })
    );

    series1.data.setAll(resultData);

    // series1.columns.template.setAll({
    //   tooltipY: am5.percent(10),
    //   templateField: "columnSettings",
    // });

    series1.data.setAll(resultData);

    // var series2 = chart.series.push(
    //   am5xy.LineSeries.new(root, {
    //     name: "누적 재해 현황",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "line",
    //     categoryXField: "months",
    //     tooltip: am5.Tooltip.new(root, {
    //       pointerOrientation: "horizontal",
    //       labelText: "{name} in {categoryX}: {valueY} {info}",
    //     }),
    //   })
    // );
    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "누적 재해 현황",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "line",
        categoryXField: "months",
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: "horizontal",
        //   labelText: "{name} in {categoryX}: {valueY}",
        // }),
      })
    );
    series2.strokes.template.setAll({
      strokeWidth: 2,
      //   templateField: "strokeSettings",
    });
    series2.set("stroke", "rgb(103, 148, 220)");

    series2.data.setAll(resultData);

    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series2.get("stroke"),
          radius: 3,
          fill: am5.color(0xffffff),
        }),
      });
    });

    // series2.strokes.template.setAll({
    //   strokeWidth: 3,
    //   templateField: "strokeSettings",
    // });

    // series2.data.setAll(resultData);

    // series2.bullets.push(function () {
    //   return am5.Bullet.new(root, {
    //     sprite: am5.Circle.new(root, {
    //       strokeWidth: 3,
    //       stroke: series2.get("stroke"),
    //       radius: 5,
    //       fill: root.interfaceColors.get("background"),
    //     }),
    //   });
    // });

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // var legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     centerX: am5.p50,
    //     x: am5.p50,
    //   })
    // );
    // legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
    series1.appear();

    return () => {
      root.dispose();
    };
  }, []);
  return <div id="chartdiv1" style={{ height: "120px" }}></div>;
};

export default SafetyBarChart;
const resultData = [
  { months: "1월", column: 0, line: 0 },
  { months: "2월", column: 0, line: 0 },
  { months: "3월", column: 0, line: 0 },
  { months: "4월", column: 0, line: 0 },
  { months: "5월", column: 0, line: 0 },
  { months: "6월", column: 0, line: 0 },
  { months: "7월", column: 0, line: 0 },
  { months: "8월", column: 0, line: 0 },
  { months: "9월", column: 0, line: 0 },
  { months: "10월", column: 0, line: 0 },
  { months: "11월", column: 0, line: 0 },
  { months: "12월", column: 0, line: 0 },
];

// const resultData = [
//   {
//     months: "1월",
//     column: 0,
//     line: 0,
//   },
//   {
//     months: "2월",
//     column: 1,
//     line: 1,
//   },
//   {
//     months: "3월",
//     column: 2,
//     line: 3,
//   },
//   {
//     months: "4월",
//     column: 0,
//     line: 3,
//   },
//   {
//     months: "5월",
//     column: 3,
//     line: 6,
//   },
//   {
//     months: "6월",
//     column: 1,
//     line: 7,
//   },
//   {
//     months: "7월",
//     column: 0,
//     line: 7,
//   },
//   {
//     months: "8월",
//     column: 0,
//     line: 7,
//   },
//   {
//     months: "9월",
//     column: 2,
//     line: 9,
//   },
//   {
//     months: "10월",
//     column: 0,
//     line: 9,
//   },
//   {
//     months: "11월",
//     column: 1,
//     line: 10,
//   },
//   {
//     months: "12월",
//     column: 2,
//     line: 12,
//   },
// ];
