import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import styled from "styled-components";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const CHART_ID = "timeline_chart";

const TotalKPIChart = ({ height, data, objList }) => {
  const [targetDate, setTargetDate] = useState(null);
  const [chartLengendData, setChartLegendData] = useState([]);

  useEffect(() => {
    const obj_list_date_range = calculation_cum_data(objList);

    let root = am5.Root.new(CHART_ID);

    root.setThemes([am5themes_Animated.new(root)]);

    let yearData = { ...data };

    let firstYearMonth = Number(objList[0]);

    // const last_item =
    //   objList.length > 1 ? objList.length - 2 : objList.length - 1;

    // let lastYearMonth = Number(objList[last_item]);

    let currentYearMonth = firstYearMonth;

    let newData = {};

    am5.object.each(yearData, function (yearMonth, yearDataItems) {
      am5.array.each(yearDataItems, function (item) {
        if (!newData[item.name]) {
          newData[item.name] = {
            data: [],
            continent: item.continent,
          };
        }

        newData[item.name].data.push(item);
      });
    });
    // main container
    let mainContainer = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.verticalLayout,
      })
    );

    // Create chart
    let chart = mainContainer.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelY: "zoomXY",
        pinchZoomX: true,
        pinchZoomY: true,
        layout: root.verticalLayout,
      })
    );
    // Create child elements
    function createRectangle(color, x, y) {
      let rectangle = chart.plotContainer.children.push(
        am5.Rectangle.new(root, {
          width: am5.p50,
          height: am5.p50,
          fill: color,
          fillOpacity: 0.5,
          stroke: "rgba(224, 224, 224, 0.5)",
          x: x,
          y: y,
        })
      );
      return rectangle;
    }

    createRectangle("rgb(205, 33, 42, 0.1)", am5.p0, am5.p50);
    createRectangle("rgb(0, 161, 112, 0.1)", am5.p50, am5.p0);
    createRectangle("rgb(245, 223, 77, 0.1)", am5.p0, am5.p0);
    createRectangle("rgb(245, 223, 77, 0.1)", am5.p50, am5.p50);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 500,
      maxGridDistance: 500,
    });

    xRenderer.labels.template.setAll({
      paddingTop: 10,
      fill: "#333",
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0.5,
        max: 1.5,
        strictMinMax: true,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      stroke: "#fff",
    });

    xAxis.children.push(
      am5.Label.new(root, {
        text: "SPI 일정성과지수 (%)",
        x: am5.p50,
        centerX: am5.p50,
        fill: "#333",
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 100,
    });

    yRenderer.grid.template.setAll({
      stroke: "#fff",
    });

    yRenderer.labels.template.setAll({
      fill: "#333",
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0.5,
        max: 1.5,
        strictMinMax: true,
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.children.moveValue(
      am5.Label.new(root, {
        text: "CPI 원가성과지수 (%)",
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        fill: "#333",
      }),
      0
    );
    // createSeries("Series #1", "value");
    // createSeries("Series #2", "value2");

    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let bubbleSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "y",
        valueXField: "x",
        valueField: "value",
        legendLabelText: "{name}",
        legendRangeLabelText: "{name}",
      })
    );

    bubbleSeries.strokes.template.set("visible", false);
    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    let circleTemplate = am5.Template.new({ tooltipY: 0 });

    circleTemplate.states.create("transparent", { opacity: 0.15 });
    circleTemplate.events.on("pointerover", handleOver);
    circleTemplate.events.on("pointerout", handleOut);
    circleTemplate.events.on("click", handleClick);

    function handleOver(e) {
      let target = e.target;
      am5.array.each(bubbleSeries.dataItems, function (dataItem) {
        if (dataItem.bullets) {
          let bullet = dataItem.bullets[0];
          if (bullet) {
            let sprite = bullet.get("sprite");
            if (sprite && sprite !== target) {
              sprite.states.applyAnimate("transparent");
            }
          }
        }
      });
    }

    function handleOut(e) {
      am5.array.each(bubbleSeries.dataItems, function (dataItem) {
        if (dataItem.bullets) {
          let bullet = dataItem.bullets[0];
          if (bullet) {
            let sprite = bullet.get("sprite");
            if (sprite) {
              sprite.states.applyAnimate("default");
            }
          }
        }
      });
    }

    let selectedDataItem;

    function handleClick(e) {
      if (selectedDataItem === e.target.dataItem) {
        am5.array.each(bubbleSeries.dataItems, function (dataItem) {
          let bullet = dataItem.bullets[0];
          let sprite = bullet.get("sprite");
          sprite.set("fillOpacity", 1);
        });
        lineSeries.data.clear();
      } else {
        selectedDataItem = e.target.dataItem;

        lineSeries.data.setAll(newData[selectedDataItem.dataContext.name].data);
        lineSeries.show();

        am5.array.each(bubbleSeries.dataItems, function (dataItem) {
          let bullet = dataItem.bullets[0];
          let sprite = bullet.get("sprite");
          if (dataItem !== e.target.dataItem) {
            sprite.set("fillOpacity", 0.15);
          } else {
            sprite.set("fillOpacity", 1);
          }
        });
      }
    }

    bubbleSeries.bullets.push(function () {
      let bulletCircle = am5.Circle.new(
        root,
        {
          // radius: 5,
          templateField: "setting",
          fillOpacity: 0.9,
          tooltipText:
            "[fontSize:18px; bold]{name}[/]\nCPI: {valueY}\nSPI: {valueX}\n프로젝트 규모(예산): {value}억",
        },
        circleTemplate
      );

      return am5.Bullet.new(root, {
        sprite: bulletCircle,
      });
    });

    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        min: 25,
        max: 40,
        dataField: "value",
        key: "radius",
        maxValue: 4000,
      },
    ]);

    bubbleSeries.bullets.push(function () {
      let bubble_series_label = am5.Label.new(root, {
        text: "{name}",
        fill: "#fff",
        centerY: am5.p50,
        centerX: am5.p50,
        populateText: true,
        fontSize: "1rem",

        // oversizedBehavior: "truncate",
      });

      bubble_series_label.adapters.add("centerY", function (centerY, target) {
        // const value_arr = _.uniq(
        //   Object.keys(data)
        //     .map((com) => data[com])
        //     .flat()
        //     .map((com) => com.value)
        // );

        // const max = Math.max(...value_arr);
        // const min = Math.min(...value_arr);

        // const data_item = target.dataItem.dataContext;

        // return ((data_item.value - min) / (max - min)) * 50;

        return centerY;
      });

      return am5.Bullet.new(root, {
        // locationY: 0,
        sprite: bubble_series_label,
      });
    });

    // line series
    let lineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        valueXField: "x",
        valueYField: "y",
        xAxis: xAxis,
        yAxis: yAxis,
        stroke: am5.color(0x00000),
        // stroke: "#333",
      })
    );

    lineSeries.strokes.template.set("strokeOpacity", 0.9);

    lineSeries.bullets.push(function () {
      let bulletCircle = am5.Circle.new(root, {
        radius: 2,
        fill: lineSeries.stroke,
      });

      return am5.Bullet.new(root, {
        sprite: bulletCircle,
      });
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        snapToSeries: [bubbleSeries],
      })
    );

    let cursor = chart.get("cursor");

    cursor.lineX.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    // Add scrollbars
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        exportable: false,
      })
    );

    chart.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
        exportable: false,
      })
    );

    // Label
    let yearLabel = chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: "",
        // currentYearMonth.toString().slice(0, 4) +
        // "/" +
        // currentYearMonth.toString().slice(4, 6),
        fontSize: "3em",
        fill: "#333",
        opacity: 0.15,
        x: am5.p50,
        y: am5.p50,
        fontFamily: "Courier New",
        textAlign: "right",
        centerX: am5.p50,
        centerY: am5.p50,
      })
    );

    chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: "일정 지연\n원가 절감",
        fontSize: "1.1em",
        fill: "#333",
        opacity: 1,
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        fontFamily: "Courier New",
        textAlign: "right",
      })
    );

    chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: "일정 선행\n원가 절감",
        fontSize: "1.1em",
        fill: "#333",
        opacity: 1,
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        fontFamily: "Courier New",
        textAlign: "right",
      })
    );

    chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: "일정 지연\n원가 초과",
        fontSize: "1.1em",
        fill: "#333",
        opacity: 1,
        x: 0,
        y: am5.p100,
        centerX: 0,
        centerY: am5.p100,

        fontFamily: "Courier New",
        textAlign: "right",
      })
    );

    chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: "일정 선행\n원가 초과",
        fontSize: "1.1em",
        fill: "#333",
        opacity: 1,
        x: am5.p100,
        y: am5.p100,
        centerX: am5.p100,
        centerY: am5.p100,
        fontFamily: "Courier New",
        textAlign: "right",
      })
    );

    // Create controls
    let yearSliderContainer = mainContainer.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        crisp: true,
        layout: root.horizontalLayout,
        paddingLeft: 70,
        paddingRight: 40,
        exportable: false,
      })
    );

    let playButton = yearSliderContainer.children.push(
      am5.Button.new(root, {
        themeTags: ["play"],
        centerY: am5.p50,
        marginRight: 20,
        icon: am5.Graphics.new(root, {
          themeTags: ["icon"],
        }),
      })
    );

    playButton.events.on("click", function () {
      if (playButton.get("active")) {
        slider.set("start", slider.get("start") + 0.0001);
      } else {
        if (slider.get("start") === 1) {
          slider.set("start", 0);
        }

        slider.animate({
          key: "start",
          to: 1,
          duration: 15000 * (1 - slider.get("start")),
        });
      }
    });

    let slider = yearSliderContainer.children.push(
      am5.Slider.new(root, {
        orientation: "horizontal",
        start: 1,
        centerY: am5.p50,
      })
    );

    slider.on("start", function (start) {
      if (start === 1) {
        playButton.set("active", false);
      }
    });

    // let firstYear = Math.floor(firstYearMonth / 100);
    // let firstMonth = firstYearMonth % 100;
    // let lastYear = Math.floor(lastYearMonth / 100);
    // let lastMonth = lastYearMonth % 100;

    let year_observer;

    slider.events.on("rangechanged", function () {
      // const year_data =
      //   firstYearMonth +
      //   Math.round(
      //     slider.get("start", 0) *
      //       ((lastYear - firstYear) * 12 + (lastMonth - firstMonth) + 1)
      //   );

      const year_data = obj_list_date_range.find((com) => {
        const { start_value, end_value } = com;
        return (
          start_value <= slider.get("start", 0) &&
          end_value >= slider.get("start", 0)
        );
      });

      updateSeriesData(year_data.value);

      if (year_observer !== year_data.value) {
        setChartLegendData(data[year_data.value]);
        year_observer = year_data.value;
      }
    });

    function updateSeriesData(year) {
      if (currentYearMonth !== year) {
        currentYearMonth = year;
        let data = yearData[year];
        let i = 0;
        am5.array.each(data, function (item) {
          bubbleSeries.data.setIndex(i, item);
          i++;
        });
        yearLabel.set(
          "text",
          // year.toString().slice(0, 4) + "/" + year.toString().slice(4, 6)
          ""
        );
        setTargetDate(year);
      }
    }

    let sb1 = chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        y: 0,
        centerY: 0,
      })
    );

    sb1.startGrip.set("forceHidden", true);
    sb1.endGrip.set("forceHidden", true);

    sb1.hide(0);

    let sb2 = chart.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
      })
    );

    sb2.startGrip.set("forceHidden", true);
    sb2.endGrip.set("forceHidden", true);

    sb2.hide(0);

    chart.plotContainer.children.push(sb1);
    chart.plotContainer.children.push(sb2);

    bubbleSeries.data.setAll(yearData[currentYearMonth]);

    return () => {
      root && root.dispose();
    };
  }, [data, objList]);

  return (
    <>
      <LegendTotalBoxDiv>
        {[...chartLengendData].map((com, idx) => {
          return (
            com.value > 0 && (
              <LegendBoxDiv key={idx}>
                <LegendCircle legendcolor={com.setting.fill} />
                <LegendTitle>{com.name}</LegendTitle>
              </LegendBoxDiv>
            )
          );
        })}
      </LegendTotalBoxDiv>
      <ProjectKPIAmChartDiv id={CHART_ID} chartheight={400} />
    </>
  );
};

export default TotalKPIChart;

const ProjectKPIAmChartDiv = styled.div`
  width: 100%;
  height: ${(props) => {
    return props.chartheight + "px";
  }};
  z-index: 999;
  margin: auto;
`;

const LegendTotalBoxDiv = styled.div`
  display: flex;
  height: 10%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
`;

const LegendBoxDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 5px;
  height: 20px;
`;

const LegendCircle = styled.div`
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ legendcolor }) => {
    return legendcolor;
  }};
`;

const LegendTitle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: "#333";
  font-size: 0.9rem;
`;

const calculation_cum_data = (data) => {
  const data_arr_cumstom = data.map((com) => {
    return {
      value: com,
      time_value: 1 / data.length,
    };
  });

  let end_data = 0;

  return data_arr_cumstom.map((com, idx) => {
    const new_item = { ...com };

    new_item.start_value = end_data;
    new_item.end_value = end_data += com.time_value;

    return new_item;
  });
};
