import React, { useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import QualityTable1 from "./QualityTable1";
import QualityTabel2 from "./QualityTabel2";
import QualityNivoChart2 from "./QualityNivoChart2";
import QualityPQIChart from "./QualityPQIChart";
import ImageSwiper from "../Quality/ImageSwiper";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";

const ApartQuality = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const imageList = [
    { image: image5, issueTitle: "콘크리트 타설", issueStatus: "적합" },
    { image: image6, issueTitle: "레벨 측정", issueStatus: "적합" },
  ];
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen ? (width - 80 - 280) / 2 : (width - 80) / 2,
              height: (height - 100) / 3 - 10 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">PQI 현황</h3>
              </div>
            </div>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <div style={{ width: "40%", margin: "auto" }}>
                <div style={{ height: "230px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    <b>2023년 12월</b>
                  </p>
                  <QualityNivoChart2 data={data1} text="72.3점" />
                </div>
              </div>
              {/* <QualityColumnChart /> */}
              <QualityPQIChart chart_id="q" data={data3} height="180px" />
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen ? (width - 80 - 280) / 2 : (width - 80) / 2,
              height: (height - 100) / 3 - 10 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">검사 현황</h3>
              </div>
            </div>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <div style={{ width: "33%", margin: "auto" }}>
                <div style={{ height: "220px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    <b>불합격수 / 총 검사수 (전체) </b>
                  </p>
                  <QualityNivoChart2 data={data2} text="5 건 / 173 건" />
                </div>
              </div>
              <div style={{ width: "33%", margin: "auto" }}>
                <div style={{ height: "220px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    <b>미조치 부적합 / 전체 부적합 </b>
                  </p>
                  <QualityNivoChart2 data={data4} text="0 건 / 9 건" />
                </div>
              </div>
              <div style={{ width: "33%", margin: "auto" }}>
                <div style={{ height: "220px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    <b>미조치 OP / 전체 OP </b>
                  </p>
                  <QualityNivoChart2 data={data5} text="2 건 / 135 건" />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="card card-flush me-5 mb-7"
              style={{
                width: isSidebarOpen
                  ? ((width - 80 - 280) / 2) * 0.4 - 10
                  : ((width - 80) / 2) * 0.4 - 10,
                height: (height - 100) / 3,
              }}
            >
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">점검 현황</h3>
                </div>
              </div>
              <div className="card-body p-1" style={{ display: "flex" }}>
                <div style={{ width: "100%", margin: "auto" }}>
                  <div style={{ height: "220px" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        marginBottom: 0,
                      }}
                    >
                      <b>미조치 지적 / 전체 지적 </b>
                    </p>
                    <QualityNivoChart2 data={data6} text="11 건 / 17 건" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card card-flush me-5 mb-7"
              style={{
                width: isSidebarOpen
                  ? ((width - 80 - 280) / 2) * 0.6 - 10
                  : ((width - 80) / 2) * 0.6 - 10,
                height: (height - 100) / 3,
              }}
            >
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">품질 이슈 사진</h3>
                </div>
              </div>
              <div className="card-body p-7 pt-0 pb-0">
                <div>
                  <ImageSwiper
                    height={((height - 100) / 3 - 30 - 60) * 0.8}
                    width={
                      isSidebarOpen
                        ? ((width - 80 - 280) / 2) * 0.6 - 10 - 10 - 45
                        : ((width - 80) / 2) * 0.6 - 10 - 10 - 45
                    }
                    imageList={imageList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen ? (width - 80 - 280) / 2 : (width - 80) / 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">품질 이슈 및 리스크</h3>
              </div>
            </div>
            <div className="card-body px-9 mb-9 pt-0 scroll">
              <QualityTable1
                width={
                  isSidebarOpen
                    ? (width - 80 - 280) / 2 - 60
                    : (width - 80) / 2 - 60
                }
              />
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen ? (width - 80 - 280) / 2 : (width - 80) / 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">협력사별 품질 순위</h3>
              </div>
            </div>
            <div className="card-body py-1 pt-0 mb-9 scroll">
              <QualityTabel2
                width={
                  isSidebarOpen ? (width - 80 - 280) / 2 : (width - 80) / 2
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartQuality;
const data1 = [
  {
    id: "완료",
    label: "완료",
    value: 27.7,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "미완료",
    label: "미완료",
    value: 72.3,
    color: "hsl(310, 70%, 50%)",
  },
];
const data2 = [
  {
    id: "불합격",
    label: "불합격",
    value: 5,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "합격",
    label: "합격",
    value: 173 - 5,
    color: "hsl(310, 70%, 50%)",
  },
];

const data3 = [
  {
    year: "23년 01월",
    act: 0,
    plan: 2,
    cum_act: 90,
    // cum_plan: 90,
  },
  {
    year: "23년 02월",
    act: 0,
    plan: 1,
    cum_act: 80,
    // cum_plan: 0,
  },
  {
    year: "23년 03월",
    act: 0,
    plan: 4,
    cum_act: 91,
    // cum_plan: 0,
  },
  {
    year: "23년 04월",
    act: 0,
    plan: 2,
    cum_act: 90,
    // cum_plan: 90,
  },
  {
    year: "23년 05월",
    act: 0,
    plan: 1,
    cum_act: 98,
    // cum_plan: 0,
  },
  {
    year: "23년 06월",
    act: 0,
    plan: 4,
    cum_act: 91,
    // cum_plan: 0,
  },
];
const data4 = [
  {
    id: "미조치 부적합",
    label: "미조치 부적합",
    value: 0,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "조치 부적합",
    label: "조치 부적합",
    value: 9,
    color: "hsl(310, 70%, 50%)",
  },
];
const data5 = [
  {
    id: "미조치 OP",
    label: "미조치 OP",
    value: 2,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "조치 OP",
    label: "조치 OP",
    value: 135,
    color: "hsl(310, 70%, 50%)",
  },
];
const data6 = [
  {
    id: "미조치 지적",
    label: "미조치 지적",
    value: 11,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "조치 지적",
    label: "조치 지적",
    value: 17,
    color: "hsl(310, 70%, 50%)",
  },
];
