import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const CostChart1 = ({ chart_id, data, height }) => {
  useEffect(() => {
    var root = am5.Root.new(chart_id);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });
    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);
    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString() +
            " 억";
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });
    // let tooltip = am5.Tooltip.new(root, {});

    // chart.plotContainer.set("tooltipPosition", "pointer");
    // chart.plotContainer.set("tooltipText", "a");
    // chart.plotContainer.set("tooltip", tooltip);

    // tooltip.label.adapters.add("text", function (text, target) {
    //   text = "";
    //   let i = 0;
    //   chart.series.each(function (series) {
    //     let tooltipDataItem = series.get("tooltipDataItem");

    //     if (tooltipDataItem) {
    //       if (i === 0) {
    //         text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
    //       }

    //       if (i !== 0) {
    //         text += "\n";
    //       }

    //       text +=
    //         "[" +
    //         series.get("stroke") +
    //         "]●[/] [width:100px]" +
    //         series.get("name") +
    //         ":[/] " +
    //         Number(
    //           (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
    //         ).toLocaleString() +
    //         " 건";
    //     }
    //     i++;
    //   });
    //   return text;
    // });
    // tooltip.get("background").setAll({
    //   stroke: am5.color(0x000000),
    //   strokeOpacity: 0.8,
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.8,
    // });
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
    });

    // var xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    //     categoryField: "year",
    //     renderer: xRenderer,
    //     tooltip: am5.Tooltip.new(root, {}),
    //   })
    // );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
      })
    );
    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0,
    });

    xRenderer.labels.template.setAll({
      paddingTop: 10,
      scale: 0.7,
      fill: "#333",
    });

    xAxis.data.setAll(data);
    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
      grid: {
        disabled: true,
      },
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        strictMinMaxSelection: true,
        renderer: yRenderer,
      })
    );
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      paddingRight: 10,
      scale: 0.7,
      // fill: "#fff",
    });
    let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
    });
    let paretoAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
        visible: true,
      })
    );
    paretoAxisRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    paretoAxisRenderer.labels.template.setAll({
      paddingTop: 10,
      scale: 0.7,
      // fill: "#fff",
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, color) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          legendLabelText: name + " ({valueY})",
          legendRangeLabelText: name,
          // fill: color,
          // stroke: color,
        })
      );

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      // series.bullets.push(function () {
      //   return am5.Bullet.new(root, {
      //     locationY: 0,
      //     sprite: am5.Label.new(root, {
      //       text: "{valueY}",
      //       fill: root.interfaceColors.get("alternativeText"),
      //       centerY: 0,
      //       centerX: am5.p50,
      //       populateText: true,
      //     }),
      //   });
      // });

      legend.data.push(series);
      legend.markers.template.setAll({
        width: 5,
        height: 5,
      });
    }

    const makeParetoSeries = (name, fieldName) => {
      let paretoSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: paretoAxis,
          valueYField: fieldName,
          categoryXField: "year",
          legendLabelText: name + " ({valueY})",
          legendRangeLabelText: name,
          // stroke: color,
          // fill: color,
          // maskBullets: false,
          // tooltip: am5.Tooltip.new(root, {
          //   labelText:
          //     "[color:black]{name}[/]\n{valueX.formatDate()}: {valueY}",
          // }),
        })
      );

      paretoSeries.strokes.template.setAll({
        strokeWidth: 2,
      });

      paretoSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 3,
            stroke: paretoSeries.get("stroke"),
            strokeWidth: 2,
            fill: am5.color(0xffffff),
          }),
        });
      });

      paretoSeries.data.setAll(data);

      legend.data.push(paretoSeries);
      // legend.labels.template.setAll({
      //   fontSize: 12,
      // });
    };

    makeSeries("매출", "act");
    makeSeries("원가", "plan");
    makeParetoSeries("누적 매출", "cum_act");
    makeParetoSeries("누적 원가", "cum_plan");

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    return () => {
      root && root.dispose();
    };
  });
  return <div id={chart_id} style={{ width: "100%", height: height }}></div>;
};

export default CostChart1;
