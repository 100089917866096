import React, { useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import ProgressChart2 from "./ProgressChart2";
import ProgressStatus from "./ProgressStatus";
import TotalCostChart from "../../Total/TotalCostChart";

const ApartProgress = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          //   display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Construction Progress</h3>
              </div>
            </div>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <div style={{ width: "40%" }}>
                <div
                  className="card-rounded bg-body position-relative card-px mt-5"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="row g-0 mb-7" style={{ textAlign: "center" }}>
                    <div className="col mx-5">
                      <div className="fs-6 text-gray-500 fw-bold my-1">
                        Plan
                      </div>
                      <div className="fs-1 fw-bold text-gray-800">98.80 %</div>
                    </div>
                    <div className="col mx-5">
                      <div className="fs-6 text-gray-500 fw-bold my-1">
                        Actual
                      </div>
                      <div className="fs-1 fw-bold text-gray-800">98.64 %</div>
                    </div>
                  </div>
                  <div className="row g-0" style={{ textAlign: "center" }}>
                    <div className="col mx-5">
                      <div className="fs-6 text-gray-500 fw-bold my-1">
                        Dev.
                      </div>
                      <div className="fs-1 fw-bold text-danger">- 0.16 %</div>
                    </div>
                  </div>
                </div>

                <div className="px-7 py-3 pt-8">
                  <div className="z-3">
                    <select className="form-select form-select-solid">
                      <option>Area 1</option>
                      <option>Area 2</option>
                      <option>Area 3</option>
                    </select>
                  </div>
                </div>
                <div className="px-7 py-3 ">
                  <div className="z-3">
                    <select className="form-select form-select-solid">
                      <option>공종 1</option>
                      <option>공종 2</option>
                      <option>공종 3</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <TotalCostChart
                  chart_id="progress1"
                  data={data2}
                  height="300px"
                  text1=" %"
                />
              </div>
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">공정 현황 기재란</h3>
              </div>
            </div>
            <div className="card-body p-9 mb-9 scroll">
              <ProgressStatus />
            </div>
          </div>
        </div>
        <div
          className="card card-flush me-0 mb-7"
          style={{
            width: isSidebarOpen ? width - 80 - 280 - 10 : width - 80 - 10,
            height: (height - 100) / 2 - 10,
          }}
        >
          <div className="card-header">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Progress Detail(공종별)</h3>
            </div>
          </div>
          <div className="card-body p-1 pt-1">
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <ProgressChart2
                id="3"
                title="공통"
                percent={90}
                color="#f1415c"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <BulletChart /> */}
              <ProgressChart2
                id="4"
                title="콘크리트공사"
                percent={100}
                color="#172A4B"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <ProgressChart2 id="3" percent={40} color="#172A4B" />
              <ProgressChart2 id="4" percent={90} color="#172A4B" /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <ProgressChart2
                id="7"
                title="철골공사"
                percent={100}
                color="#172A4B"
                width={700}
                height={70}
                marginBottom={30}
              />
              <ProgressChart2
                id="8"
                title="전기공사"
                percent={40}
                color="#f1415c"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <ProgressChart2 id="7" percent={90} color="#172A4B" />
              <ProgressChart2 id="8" percent={90} color="#172A4B" /> */}
            </div>
          </div>
        </div>
        <div
          className="card card-flush me-5 mb-7"
          style={{
            width: isSidebarOpen ? width - 80 - 280 - 10 : width - 80 - 10,
            height: (height - 100) / 2 - 10,
          }}
        >
          <div className="card-header">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Progress Detail(협력사별)</h3>
            </div>
          </div>
          <div className="card-body p-9 pt-1">
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <ProgressChart2
                id="1"
                title="협력사 1"
                percent={90}
                color="#f1415c"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <BulletChart /> */}
              <ProgressChart2
                id="2"
                title="협력사 2"
                percent={100}
                color="#172A4B"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <ProgressChart2 id="3" percent={40} color="#172A4B" />
              <ProgressChart2 id="4" percent={90} color="#172A4B" /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <ProgressChart2
                id="5"
                title="협력사 3"
                percent={100}
                color="#172A4B"
                width={700}
                height={70}
                marginBottom={30}
              />
              <ProgressChart2
                id="6"
                title="협력사 4"
                percent={40}
                color="#f1415c"
                width={700}
                height={70}
                marginBottom={30}
              />
              {/* <ProgressChart2 id="7" percent={90} color="#172A4B" />
              <ProgressChart2 id="8" percent={90} color="#172A4B" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartProgress;
var data2 = [
  {
    year: "2023-01",
    act: 0,
    plan: 5,
    cum_act: 0,
    cum_plan: 5,
  },
  {
    year: "2023-02",
    act: 5,
    plan: 5,
    cum_act: 5,
    cum_plan: 10,
  },
  {
    year: "2023-03",
    act: 5,
    plan: 10,
    cum_act: 10,
    cum_plan: 20,
  },
  {
    year: "2023-04",
    act: 10,
    plan: 10,
    cum_act: 20,
    cum_plan: 30,
  },
  {
    year: "2023-05",
    act: 20,
    plan: 20,
    cum_act: 40,
    cum_plan: 50,
  },
  {
    year: "2023-06",
    act: 15,
    plan: 20,
    cum_act: 55,
    cum_plan: 70,
  },
  {
    year: "2023-07",
    act: 15,
    plan: 10,
    cum_act: 70,
    cum_plan: 80,
  },
  {
    year: "2023-08",
    act: 15,
    plan: 5,
    cum_act: 80,
    cum_plan: 95,
  },
  {
    year: "2023-09",
    act: 10,
    plan: 5,
    cum_act: 90,
    cum_plan: 100,
  },
  {
    year: "2023-10",
    act: 5,
    plan: 0,
    cum_act: 95,
    cum_plan: 100,
  },
  {
    year: "2023-11",
    act: 5,
    plan: 0,
    cum_act: 100,
    cum_plan: 100,
  },
  {
    year: "2023-12",
    act: 0,
    plan: 0,
    cum_act: 100,
    cum_plan: 100,
  },
];
