import React from "react";

const TotalTypeButton = ({ activeType, setActiveType }) => {
  return (
    <>
      <div className="card-toolbar me-5" data-kt-buttons="true">
        <ul className="nav">
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activeType === "매출" ? "active" : ""
              }`}
              onClick={() => setActiveType("매출")}
            >
              매출
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm text-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activeType === "손익" ? "active" : ""
              }`}
              onClick={() => setActiveType("손익")}
            >
              손익
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activeType === "CF" ? "active" : ""
              }`}
              onClick={() => setActiveType("CF")}
            >
              CF
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TotalTypeButton;
