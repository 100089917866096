import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import logo from "../img/hdc_logo.png";

const Bar = styled.div`
  position: sticky;
  min-width: 280px;
  height: 100%;
  background-color: #0d0e12;
  /* background-color: black; */
`;
const Logo = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 200px;
  margin-bottom: 50px;
  /* background-color: #131313; */
  /* border-right: 0; */
`;
const Navigator = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { height } = useWindowDimensions();
  const handleResize = () => {
    // 화면 크기가 768px보다 작으면 사이드바를 숨김
    setIsSidebarOpen(window.innerWidth > 768);
  };
  const handleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  useEffect(() => {
    // 처음 렌더링될 때 한 번 실행
    handleResize();

    // 화면 크기가 변할 때마다 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트되면 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <button
        className="app_sidebar_toggle btn btn-icon btn-xl btn-color-black position-absolute"
        style={{ width: "40px", height: "40px", float: "left", zIndex: "99" }}
        onClick={handleSidebar}
      >
        <i
          className="ki-duotone ki-burger-menu-5"
          style={{ fontSize: "20px" }}
        ></i>
      </button>
      {isSidebarOpen && (
        <Bar>
          <Logo>
            <a
              href="/"
              style={{
                margin: "auto",
                marginBottom: 30,
              }}
            >
              <img src={logo} width="150" height="50" alt="" />
            </a>

            <h4 className="column fs-1 text-white w-bolder mx-auto">
              통합대시보드
            </h4>
          </Logo>
          <nav
            style={{
              color: "white",
              height: height - 250,
              overflow: "scroll",
            }}
          >
            <ul>
              {menuData.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </ul>
          </nav>
        </Bar>
      )}
    </>
  );
};

export default Navigator;
const menuData = [
  {
    label: "개발 사업",
    to: "/dev",
    subMenu: [
      {
        label: "서울",
        to: "/dev/seoul",
        subMenu: [
          { label: "Apt 1", to: "/dev/seoul/apt1" },
          { label: "Apt 2", to: "/dev/seoul/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경기도",
        to: "/dev/gyeonggido",
        subMenu: [
          { label: "Apt 1", to: "/dev/gyeonggido/apt1" },
          { label: "Apt 2", to: "/dev/gyeonggido/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "강원도",
        to: "/dev/gangwondo",
        subMenu: [
          { label: "Apt 1", to: "/dev/gangwondo/apt1" },
          { label: "Apt 2", to: "/dev/gangwondo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "충청북도",
        to: "/dev/chungcheongbukdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/chungcheongbukdo/apt1" },
          { label: "Apt 2", to: "/dev/chungcheongbukdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "충청남도",
        to: "/dev/chungcheongnamdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/chungcheongnamdo/apt1" },
          { label: "Apt 2", to: "/dev/chungcheongnamdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "전라북도",
        to: "/dev/jeollabukdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/jeollabukdo/apt1" },
          { label: "Apt 2", to: "/dev/jeollabukdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "전라남도",
        to: "/dev/jeollanamdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/jeollanamdo/apt1" },
          { label: "Apt 2", to: "/dev/jeollanamdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경상북도",
        to: "/dev/gyeongsangbukdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/gyeongsangbukdo/apt1" },
          { label: "Apt 2", to: "/dev/gyeongsangbukdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경상남도",
        to: "/dev/gyeongsangnamdo",
        subMenu: [
          { label: "Apt 1", to: "/dev/gyeongsangnamdo/apt1" },
          { label: "Apt 2", to: "/dev/gyeongsangnamdo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "제주도",
        to: "/dev/jejudo",
        subMenu: [
          { label: "Apt 1", to: "/dev/jejudo/apt1" },
          { label: "Apt 2", to: "/dev/jejudo/apt2" },
          // Add more sub-sub-menu items as needed
        ],
      },
      // Add more sub-menu items as needed
    ],
  },
  {
    label: "수행 사업",
    to: "/order",
    subMenu: [
      {
        label: "서울",
        to: "/order/seoul",
        subMenu: [
          { label: "Apt 3", to: "/order/seoul/apt3" },
          { label: "Apt 4", to: "/order/seoul/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경기도",
        to: "/order/gyeonggido",
        subMenu: [
          { label: "Apt 3", to: "/order/gyeonggido/apt3" },
          { label: "Apt 4", to: "/order/gyeonggido/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "강원도",
        to: "/order/gangwondo",
        subMenu: [
          { label: "Apt 3", to: "/order/gangwondo/apt3" },
          { label: "Apt 4", to: "/order/gangwondo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "충청북도",
        to: "/order/chungcheongbukdo",
        subMenu: [
          { label: "Apt 3", to: "/order/chungcheongbukdo/apt3" },
          { label: "Apt 4", to: "/order/chungcheongbukdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "충청남도",
        to: "/order/chungcheongnamdo",
        subMenu: [
          { label: "Apt 3", to: "/order/chungcheongnamdo/apt3" },
          { label: "Apt 4", to: "/order/chungcheongnamdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "전라북도",
        to: "/order/jeollabukdo",
        subMenu: [
          { label: "Apt 3", to: "/order/jeollabukdo/apt3" },
          { label: "Apt 4", to: "/order/jeollabukdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "전라남도",
        to: "/order/jeollanamdo",
        subMenu: [
          { label: "Apt 3", to: "/order/jeollanamdo/apt3" },
          { label: "Apt 4", to: "/order/jeollanamdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경상북도",
        to: "/order/gyeongsangbukdo",
        subMenu: [
          { label: "Apt 3", to: "/order/gyeongsangbukdo/apt3" },
          { label: "Apt 4", to: "/order/gyeongsangbukdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "경상남도",
        to: "/order/gyeongsangnamdo",
        subMenu: [
          { label: "Apt 3", to: "/order/gyeongsangnamdo/apt3" },
          { label: "Apt 4", to: "/order/gyeongsangnamdo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      {
        label: "제주도",
        to: "/order/jejudo",
        subMenu: [
          { label: "Apt 3", to: "/order/jejudo/apt3" },
          { label: "Apt 4", to: "/order/jejudo/apt4" },
          // Add more sub-sub-menu items as needed
        ],
      },
      // Add more sub-menu items as needed
    ],
  },
  // Add more top-level menu items as needed
];
const MenuItem = ({ label, to, subMenu, depth = 0 }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname === to;
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  const fontSize = 16 - depth * 3; // Adjust the font size based on the depth
  const marginRight = 20 - depth * 3;
  const linkProps = {
    style: {
      color: "white",
      fontSize: `${fontSize}px`,
      textDecoration: "none",
      fontWeight: "normal",
      ...(isSelected && { fontWeight: "bold" }),
    },
    to: to,
  };
  if (depth === 2) {
    linkProps.target = "_blank";
  }
  return (
    <li style={{ listStyle: "none" }}>
      <div
        onClick={toggleSubMenu}
        style={{
          cursor: "pointer",
          marginBottom: "35px",
          fontWeight: "normal",
        }}
      >
        <Link {...linkProps}>{label}</Link>
        {subMenu && subMenu.length > 0 && (
          <span
            style={{
              fontSize: `${fontSize}px `,
              float: "right",
              marginRight: `${marginRight}px`,
            }}
          >
            {isSubMenuOpen ? (
              <i className="ki-duotone ki-up"></i>
            ) : (
              <i className="ki-duotone ki-down"></i>
            )}
          </span>
        )}
      </div>
      {isSubMenuOpen && subMenu && subMenu.length > 0 && (
        <ul>
          {subMenu.map((item, index) => (
            <MenuItem key={index} {...item} depth={depth + 1} />
          ))}
        </ul>
      )}
    </li>
  );
};
