import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const ProgressChart2 = ({
  id,
  title,
  percent,
  color,
  width,
  height,
  marginBottom,
}) => {
  useEffect(() => {
    var root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    var data = [
      {
        category: "",
        from: 0,
        to: percent,
        name: "Stage #1",
        columnSettings: {
          fill: color,
        },
      },

      {
        category: "",
        from: percent,
        to: 100,
        name: "Stage #2",
        columnSettings: {
          fill: am5.color(0x9e9e9e),
        },
      },
    ];

    let yRenderer = am5xy.AxisRendererY.new(root, {});

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      location: 1,
      visible: false,
    });

    yAxis.data.setAll([{ category: "" }]);

    let xRenderer = am5xy.AxisRendererX.new(root, {});

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      visible: false,
    });

    xAxis.get("renderer").labels.template.set("forceHidden", true);

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "to",
        openValueXField: "from",
        categoryYField: "category",
        categoryXField: "name",
      })
    );

    series.columns.template.setAll({
      strokeWidth: 0,
      strokeOpacity: 0,
      height: 10,
      templateField: "columnSettings",
    });

    series.data.setAll(data);

    for (var i = 0; i < data.length; i++) {
      var rangeDataItem = xAxis.makeDataItem({
        value: data[i].from,
      });

      var range = xAxis.createAxisRange(rangeDataItem);

      rangeDataItem.get("grid").set("forceHidden", true);

      // rangeDataItem.get("tick").setAll({
      //   visible: true,
      //   length: 18,
      //   strokeOpacity: 0.2,
      // });

      rangeDataItem.get("label").setAll({
        centerX: 10,
        // centerY: -40,
        forceHidden: false,
        fontSize: 10,
        text: data[i].from + "%",
      });
    }

    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "categoryX",
        centerX: am5.percent(50),
        x: am5.percent(50),
        clickTarget: "none",
      })
    );

    legend.markerRectangles.template.setAll({
      strokeOpacity: 0,
    });

    series.appear();
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [color, id, percent]);
  return (
    <div style={{ display: "block" }}>
      <div style={{ textAlign: "center", fontSize: "12px" }}>
        <b>{title}</b>
        <br />
        <span style={{ color: color }}>{(percent - 100).toFixed(2)}%</span>
        <br />
        <span>(계획: 100% 실적: {percent}%)</span>
      </div>
      <div
        id={id}
        style={{ width: width, height: height, marginBottom: marginBottom }}
      ></div>
    </div>
  );
};

export default ProgressChart2;
