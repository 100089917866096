import React from "react";
import { useParams } from "react-router-dom";

const TotalLTIRTable = () => {
  const { id } = useParams();
  return (
    <>
      {id === undefined ? (
        <table className="table table-row-dashed table-row-gray-300">
          <tbody>
            <tr>
              <td>서울 </td>
              <td>0.025 </td>
            </tr>
            <tr>
              <td>경기도 </td>
              <td>0.024 </td>
            </tr>
            <tr>
              <td>강원도 </td>
              <td>0.020 </td>
            </tr>
            <tr>
              <td>충청북도 </td>
              <td>0.013 </td>
            </tr>
            <tr>
              <td>충청남도 </td>
              <td>0.025 </td>
            </tr>
            <tr>
              <td>전라북도 </td>
              <td>0.024 </td>
            </tr>
            <tr>
              <td>전라남도 </td>
              <td>0.020 </td>
            </tr>
            <tr>
              <td>경상북도 </td>
              <td>0.013 </td>
            </tr>
            <tr>
              <td>경상남도 </td>
              <td>0.020 </td>
            </tr>
            <tr>
              <td>제주도 </td>
              <td>0.013 </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="table table-row-dashed table-row-gray-300">
          <tbody>
            <tr>
              <td>Apt 1 </td>
              <td>0.025 </td>
            </tr>
            <tr>
              <td>Apt 2 </td>
              <td>0.024 </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default TotalLTIRTable;
