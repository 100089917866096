import React from "react";
import MyResponsivePie from "../../components/nivoPieChart";

const ProjectNivo = ({ width, height }) => {
  return (
    <div>
      <div
        className="card card-flush me-5 mb-5"
        style={{ width: width, height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">재해 현황</h3>
          </div>
        </div>
        <div className="card-body p-5">
          <MyResponsivePie data={data} />
        </div>
      </div>
    </div>
  );
};

export default ProjectNivo;

const data = [
  {
    id: "낙하",
    label: "낙하",
    value: 4,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "전도",
    label: "전도",
    value: 5,
    color: "hsl(310, 70%, 50%)",
  },
  {
    id: "협착",
    label: "협착",
    value: 2,
    color: "hsl(176, 70%, 50%)",
  },
  {
    id: "충돌",
    label: "충돌",
    value: 7,
    color: "hsl(39, 70%, 50%)",
  },
  {
    id: "기타",
    label: "기타",
    value: 3,
    color: "hsl(68, 70%, 50%)",
  },
];
