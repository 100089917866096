import React from "react";

const PeriodButton = ({ activePeriod, setActivePeriod }) => {
  return (
    <>
      <div className="card-toolbar mt-7 me-5" data-kt-buttons="true">
        <ul className="nav">
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activePeriod === "day" ? "active" : ""
              }`}
              onClick={() => setActivePeriod("day")}
            >
              Day
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm text-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activePeriod === "week" ? "active" : ""
              }`}
              onClick={() => setActivePeriod("week")}
            >
              Week
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activePeriod === "month" ? "active" : ""
              }`}
              onClick={() => setActivePeriod("month")}
            >
              Month
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PeriodButton;
