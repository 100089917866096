import React, { useEffect, useRef, useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import ManequipNivoPieChart from "./ManequipNivoPieChart";
import PeriodButton from "./PeriodButton";
import TotalStackChart from "../../Total/TotalStackChart";
import TypeButton from "./TypeButton";
import ManequipNivoPieChart2 from "./ManequipNivoPieChart2";
import DayPicker from "../../../components/DayPicker";
import dayjs from "dayjs";

const ApartManequip = () => {
  const [activePeriod1, setActivePeriod1] = useState("day");
  const [activePeriod2, setActivePeriod2] = useState("day");
  const [activeType1, setActiveType1] = useState("공종");
  const [activeType2, setActiveType2] = useState("공종");
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [dateValue1, setDateValue1] = useState(new Date());
  const [dateValue2, setDateValue2] = useState(new Date());
  const [toggleValue1, setToggleValue1] = useState(true);
  const [toggleValue2, setToggleValue2] = useState(true);

  const date_picker1 = useRef(null);
  const date_picker2 = useRef(null);

  useEffect(() => {
    const select_body = window.document.querySelector("#root");

    const handleExternalClick = (e) => {
      if (
        date_picker1?.current?.contains(e.target) ||
        !select_body.contains(e.target)
      ) {
        return;
      }

      return setToggleValue1(true);
    };

    document.addEventListener("click", handleExternalClick);

    return () => {
      document.removeEventListener("click", handleExternalClick);
    };
  }, [date_picker1]);
  useEffect(() => {
    const select_body = window.document.querySelector("#root");

    const handleExternalClick = (e) => {
      if (
        date_picker2?.current?.contains(e.target) ||
        !select_body.contains(e.target)
      ) {
        return;
      }

      return setToggleValue2(true);
    };

    document.addEventListener("click", handleExternalClick);

    return () => {
      document.removeEventListener("click", handleExternalClick);
    };
  }, [date_picker2]);
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          //   display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">인원 현황</h3>
                </div>
              </div>
              <TypeButton
                activeType={activeType1}
                setActiveType={setActiveType1}
                text1="공종"
                text2="직종"
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
              }}
              ref={date_picker1}
            >
              {toggleValue1 ? (
                <b
                  onClick={() => setToggleValue1((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                >
                  {dayjs(dateValue1).format("YYYY년 MM월 DD일 기준")}
                </b>
              ) : (
                <DayPicker value={dateValue1} setValue={setDateValue1} />
              )}
              {/* <b>2023년 12월 11일 기준</b> */}
            </p>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <ManequipNivoPieChart text="20명" fontSize="20px" />
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">주별 / 월별 인원 현황</h3>
                </div>
              </div>
              <PeriodButton
                activePeriod={activePeriod1}
                setActivePeriod={setActivePeriod1}
              />
            </div>

            <div className="card-body scroll">
              {/* <CostChart1 id="manequip1" /> */}
              <TotalStackChart
                data={data1}
                id="id1"
                height="270px"
                fontSize="0.9rem"
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">장비 현황</h3>
                </div>
              </div>
              <TypeButton
                activeType={activeType2}
                setActiveType={setActiveType2}
                text1="공종"
                text2="종류"
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
              }}
              ref={date_picker2}
            >
              {toggleValue2 ? (
                <b
                  onClick={() => setToggleValue2((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                >
                  {dayjs(dateValue2).format("YYYY년 MM월 DD일 기준")}
                </b>
              ) : (
                <DayPicker value={dateValue2} setValue={setDateValue2} />
              )}
              {/* <b>2023년 12월 11일 기준</b> */}
            </p>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <ManequipNivoPieChart2 text="5대" fontSize="20px" />
              {/* <p>2023년 12월 11일 기준</p> */}
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">주별 / 월별 장비 현황</h3>
                </div>
              </div>
              <PeriodButton
                activePeriod={activePeriod2}
                setActivePeriod={setActivePeriod2}
              />
            </div>

            <div className="card-body scroll">
              {/* <CostChart1 id="manequip2" /> */}
              <TotalStackChart data={data2} id="id2" height="270px" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartManequip;
var data1 = [
  {
    year: "12-01",
    관리자: 12,
    직영근로자수: 15,
    하수급관리자수: 20,
    하수급근로자수: 7,
  },
  {
    year: "12-02",
    관리자: 16,
    직영근로자수: 27,
    하수급관리자수: 22,
    하수급근로자수: 5,
  },
  {
    year: "12-03",
    관리자: 2,
    직영근로자수: 9,
    하수급관리자수: 19,
    하수급근로자수: 13,
  },
  {
    year: "12-04",
    관리자: 18,
    직영근로자수: 9,
    하수급관리자수: 14,
    하수급근로자수: 3,
  },
  {
    year: "12-05",
    관리자: 10,
    직영근로자수: 9,
    하수급관리자수: 11,
    하수급근로자수: 23,
  },
  {
    year: "12-06",
    관리자: 2,
    직영근로자수: 3,
    하수급관리자수: 10,
    하수급근로자수: 20,
  },
  {
    year: "12-07",
    관리자: 6,
    직영근로자수: 15,
    하수급관리자수: 4,
    하수급근로자수: 15,
  },
  {
    year: "12-08",
    관리자: 8,
    직영근로자수: 9,
    하수급관리자수: 4,
    하수급근로자수: 3,
  },
  {
    year: "12-09",
    관리자: 2,
    직영근로자수: 9,
    하수급관리자수: 11,
    하수급근로자수: 13,
  },
];
var data2 = [
  {
    year: "12-01",
    굴착기계: 5,
    운반차량: 5,
    양중장비: 1,
    기타기계: 1,
  },
  {
    year: "12-02",
    굴착기계: 2,
    운반차량: 7,
    양중장비: 2,
    기타기계: 4,
  },
  {
    year: "12-03",
    굴착기계: 0,
    운반차량: 9,
    양중장비: 2,
    기타기계: 3,
  },
  {
    year: "12-04",
    굴착기계: 2,
    운반차량: 2,
    양중장비: 2,
    기타기계: 3,
  },
  {
    year: "12-05",
    굴착기계: 1,
    운반차량: 2,
    양중장비: 7,
    기타기계: 3,
  },
  {
    year: "12-06",
    굴착기계: 3,
    운반차량: 0,
    양중장비: 2,
    기타기계: 1,
  },
  {
    year: "12-07",
    굴착기계: 5,
    운반차량: 2,
    양중장비: 0,
    기타기계: 3,
  },
  {
    year: "12-08",
    굴착기계: 3,
    운반차량: 1,
    양중장비: 2,
    기타기계: 3,
  },
  {
    year: "12-09",
    굴착기계: 1,
    운반차량: 1,
    양중장비: 1,
    기타기계: 2,
  },
];
