import React from "react";

const CostCard = () => {
  return (
    <div>
      <div
        className="card-rounded bg-body position-relative card-px"
        style={{ justifyContent: "space-between" }}
      >
        <div className="row g-0" style={{ textAlign: "center" }}>
          <div className="col mx-5">
            <div className="fs-6 text-gray-500 fw-bold mb-10">누적 이익</div>
            <div className="fs-1 fw-bold text-danger">- 1.1 억</div>
          </div>
        </div>
        <div className="row g-0 mb-7" style={{ textAlign: "center" }}>
          <div className="col mx-5">
            <div className="fs-6 text-gray-500 fw-bold my-10">예상 손익</div>
            <div className="fs-1 fw-bold text-gray-800">20.5 억</div>
          </div>
          <div className="col mx-5">
            <div className="fs-6 text-gray-500 fw-bold my-10">예상 이익률</div>
            <div className="fs-1 fw-bold text-gray-800">5.25 %</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCard;
