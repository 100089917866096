import * as React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-default/scss/datepicker/_index.scss";

const DayPicker = ({ value, setValue, date }) => {
  const handleChange = (e) => {
    setValue(e.value);
  };

  return (
    <div>
      <DatePicker
        width={200}
        value={value}
        onChange={handleChange}
        format="yyyy년 MM월 dd일 기준"
      />
    </div>
  );
};

export default DayPicker;
