import React, { useState } from "react";
import Navigator2 from "../../components/Navigator2";
import ApartInfo from "./ApartInfo";
import ProjectMilestone from "../Project/ProjectMilestone";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import ProjectNivo from "../Project/ProjectNivo";
import ProjectCards from "../Project/ProjectCards";
import ProejectCards2 from "../Project/ProjectCards2";
import ProjectCards3 from "../Project/ProjectCards3";
import ProjectCards4 from "../Project/ProjectCards4";
import ProjectCards5 from "../Project/ProjectCards5";

const ApartTotal = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <ApartInfo
          width={
            isSidebarOpen
              ? (width - 80 - 280) / 4 - 5 - 5
              : (width - 80) / 4 - 5 - 5
          }
          height={height - 100 + 10}
        />
        <div>
          <ProjectMilestone
            width={
              isSidebarOpen
                ? ((width - 80 - 280) / 4) * 3
                : ((width - 80) / 4) * 3
            }
            height={(height - 100) / 3 - 10}
          />
          <div style={{ display: "flex" }}>
            <ProjectCards5
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
            <ProjectCards3
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
            <ProjectCards4
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
          </div>
          <div style={{ display: "flex" }}>
            <ProjectCards
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
            <ProjectNivo
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
            <ProejectCards2
              width={
                isSidebarOpen
                  ? (width - 80 - 280) / 4 - 5 - 5
                  : (width - 80) / 4 - 5 - 5
              }
              height={(height - 100) / 3 - 10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartTotal;
