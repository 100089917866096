import React from "react";

const CostCard2 = () => {
  return (
    <>
      <div className="flex-grow-1 ">
        <p className="text-dark fw-bold fs-4">■ 추정완성 (23.9 기준)</p>
        <div className="py-3 ms-7">
          <p>- 매출 578.49억원 / 손익 (+) 36.16 억원 (이익률 6.25%)</p>
          <p style={{ fontStyle: "italic" }}>* 정산금 37.30억원 반영</p>
        </div>
      </div>
    </>
  );
};

export default CostCard2;
