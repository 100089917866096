import React from "react";
import styled from "styled-components";

const TotalPQI = () => {
  return (
    <>
      <ContentsCenterDiv>
        <b style={{ fontSize: "40px", color: "rgb(243, 115, 32)" }}>72.3</b>

        <PqiBoxDiv>
          <NormalText
            font_size={3.5}
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginRight: "10px",
            }}
          >
            23년 12월
          </NormalText>
          <NormalText
            // font_color={
            //   overall_data.dev_prev_months === 0
            //     ? "#fff"
            //     : overall_data.up
            //     ? "#4292c6"
            //     : color_list.behind
            // }
            font_size={1.0}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <span style={{ color: "#EC4D37" }}>▲ 3</span>
            {/* {overall_data.dev_prev_months === 0
              ? "-"
              : overall_data.up
              ? "▲"
              : "▼"}{" "} */}
            {/* {overall_data.dev_prev_months} */}
          </NormalText>
        </PqiBoxDiv>
      </ContentsCenterDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            // display: "flex",
            textAlign: "center",
          }}
        >
          <NormalText>
            <span style={{ color: "#008080" }}>● Good</span>
          </NormalText>
          <NormalText>PQI {">="} 80</NormalText>
        </div>

        <div
          style={{
            // display: "flex",
            textAlign: "center",
          }}
        >
          <NormalText>
            <span style={{ color: "rgb(243, 115, 32)" }}>● Avg.</span>
          </NormalText>
          <NormalText>
            70 {"<="} PQI {">"} 80
          </NormalText>
        </div>
        <div
          style={{
            // display: "flex",
            textAlign: "center",
          }}
        >
          <NormalText>
            <span style={{ color: "#EC4D37" }}>● Bad</span>
          </NormalText>
          <NormalText>PQI {"<"} 70</NormalText>
        </div>
      </div>
    </>
  );
};

export default TotalPQI;
const ContentsCenterDiv = styled.div`
  display: flex;
  align-items: center;
  /* height: 100%; */
  flex-direction: column;
  justify-content: center;
`;
const PqiBoxDiv = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
`;
const NormalText = styled.div`
  /* color: rgb(38, 38, 38); */
  word-break: break-word;
`;
