import React from "react";
import { useParams } from "react-router-dom";

const PhotoCard = () => {
  const { aptid } = useParams();
  return (
    <>
      <div className="flex-grow-1 ">
        <p className="text-dark fw-bold fs-4">■ {aptid} 현황</p>
        <div className="py-3 ms-7">
          <p>주요공정 Weekly</p>
          <p>{aptid} 현황</p>
        </div>
      </div>
    </>
  );
};

export default PhotoCard;
