import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const TotalStackChart = ({ data, id, height, fontSize }) => {
  useEffect(() => {
    var root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0,
        layout: root.verticalLayout,
      })
    );

    // chart.set(
    //   "scrollbarX",
    //   am5.Scrollbar.new(root, {
    //     orientation: "horizontal",
    //     maxHeight: 5,
    //   })
    // );
    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);
    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString() +
            " 명";
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    // let scrollbarX = chart.get("scrollbarX");

    // scrollbarX.startGrip.setAll({
    //   visible: false,
    // });

    // scrollbarX.endGrip.setAll({
    //   visible: false,
    // });

    // chart.bottomAxesContainer.children.push(scrollbarX);
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#333",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      strokeOpacity: 0,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0,
    });
    xRenderer.labels.template.setAll({
      paddingTop: 10,
      // scale: 0.7,
      fontSize: fontSize,
      fill: "#333",
    });
    xAxis.data.setAll(data);
    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
      grid: {
        disabled: true,
      },
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
      })
    );
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      paddingRight: 10,
      scale: 0.7,
      // fill: "#fff",
    });
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        height: am5.percent(15),
        verticalScrollbar: am5.Scrollbar.new(root, {}),
        // marginTop: 10,
      })
    );
    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.labels.template.setAll({
      fontSize: fontSize,
      fontWeight: "400",
      width: 70,
    });

    function makeSeries(name, fieldName) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          legendLabelText: name + " ({valueY})",
          legendRangeLabelText: name,
        })
      );

      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
            oversizedBehavior: "hide",
          }),
        });
      });

      legend.data.push(series);
    }
    const keys = Object.keys(data[0]).filter((key) => key !== "year");
    keys.forEach((key) => makeSeries(key, key));

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, fontSize, id]);
  return <div id={id} style={{ width: "100%", height: height }}></div>;
};

export default TotalStackChart;
