import React, { useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import CostChart1 from "./CostChart1";
import CostChart2 from "./CostChart2";
import CostCard from "./CostCard";
import CostCard2 from "./CostCard2";

const ApartCost = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <div
          className="card card-flush me-5 mb-7"
          style={{
            width: isSidebarOpen ? width - 80 - 280 : width - 80,
            height: (height - 100) / 2 - 10,
          }}
        >
          <div className="card-header">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">손익 현황</h3>
            </div>
          </div>

          <div className="card-body p-2" style={{ display: "flex" }}>
            <CostChart1 chart_id="id1" data={data2} height="300px" />
            <div style={{ width: "40%", margin: "auto" }}>
              <CostCard />
            </div>
          </div>
          {/* <CostChart1 id="id2" /> */}
        </div>
        <div
          className="card card-flush me-5 mb-7"
          style={{
            width: isSidebarOpen ? width - 80 - 280 : width - 80,
            height: (height - 100) / 2 - 10,
          }}
        >
          <div className="card-header">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">자금 수지</h3>
            </div>
          </div>
          <div className="card-body p-2 " style={{ display: "flex" }}>
            <CostChart2 chart_id="id2" data={data3} height="300px" />
            <div
              style={{
                width: "40%",
                height: "100%",
                marginLeft: "10px",
                marginBottom: "50px",
                // overflow: "scroll",
              }}
            >
              <CostCard2 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartCost;
var data2 = [
  {
    year: "2023-01",
    act: 0.96,
    plan: 0.83,
    cum_act: 0.96,
    cum_plan: 0.83,
  },
  {
    year: "2023-02",
    act: 0.66,
    plan: 0.6,
    cum_act: 1.62,
    cum_plan: 1.43,
  },
  {
    year: "2023-03",
    act: 0.41,
    plan: 0.34,
    cum_act: 2.03,
    cum_plan: 1.77,
  },
  {
    year: "2023-04",
    act: 25.83,
    plan: 25.76,
    cum_act: 27.86,
    cum_plan: 27.53,
  },
  {
    year: "2023-05",
    act: 56.02,
    plan: 60.62,
    cum_act: 83.88,
    cum_plan: 88.15,
  },
  {
    year: "2023-06",
    act: 116.75,
    plan: 122.73,
    cum_act: 200.63,
    cum_plan: 210.88,
  },
  {
    year: "2023-07",
    act: 61.13,
    plan: 66.31,
    cum_act: 261.76,
    cum_plan: 277.19,
  },
  {
    year: "2023-08",
    act: 1.12,
    plan: 1.19,
    cum_act: 262.88,
    cum_plan: 278.38,
  },
  {
    year: "2023-09",
    act: 2.19,
    plan: 2.36,
    cum_act: 265.07,
    cum_plan: 280.74,
  },
  {
    year: "2023-10",
    act: 3.72,
    plan: 1.51,
    cum_act: 268.78,
    cum_plan: 282.24,
  },
  {
    year: "2023-11",
    act: 8.2,
    plan: 2.1,
    cum_act: 276.99,
    cum_plan: 284.35,
  },
  {
    year: "2023-12",
    act: 1.14,
    plan: 1.17,
    cum_act: 278.12,
    cum_plan: 285.52,
  },
];
var data3 = [
  {
    year: "2023-01",
    act: 50.75,
    plan: -0.96,
    cum_act: 49.79,
    cum_plan: 49.79,
  },
  {
    year: "2023-02",
    act: 0,
    plan: -12.07,
    cum_act: -12.07,
    cum_plan: 37.71,
  },
  {
    year: "2023-03",
    act: 0,
    plan: -0.26,
    cum_act: -0.26,
    cum_plan: 37.46,
  },
  {
    year: "2023-04",
    act: 0,
    plan: -26.31,
    cum_act: -26.31,
    cum_plan: 11.14,
  },
  {
    year: "2023-05",
    act: 0,
    plan: -57.77,
    cum_act: -57.77,
    cum_plan: -46.63,
  },
  {
    year: "2023-06",
    act: 0,
    plan: -120.08,
    cum_act: -120.08,
    cum_plan: -166.71,
  },
  {
    year: "2023-07",
    act: 119.49,
    plan: -66.15,
    cum_act: 53.34,
    cum_plan: -113.37,
  },
  {
    year: "2023-08",
    act: 112.9,
    plan: -3.01,
    cum_act: 109.89,
    cum_plan: -3.48,
  },
  {
    year: "2023-09",
    act: 0,
    plan: -1.15,
    cum_act: -1.15,
    cum_plan: -4.63,
  },
  {
    year: "2023-10",
    act: 2.75,
    plan: -3.6,
    cum_act: -0.85,
    cum_plan: -5.48,
  },
  {
    year: "2023-11",
    act: 0,
    plan: -4.03,
    cum_act: -4.03,
    cum_plan: -9.51,
  },
  {
    year: "2023-12",
    act: 0,
    plan: -11.17,
    cum_act: -11.17,
    cum_plan: -21.24,
  },
];
