import React from "react";

const TypeButton = ({ activeType, setActiveType, text1, text2 }) => {
  return (
    <>
      <div className="card-toolbar mt-7 me-5" data-kt-buttons="true">
        <ul className="nav">
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activeType === `${text1}` ? "active" : ""
              }`}
              onClick={() => setActiveType(text1)}
            >
              {text1}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm text-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activeType === `${text2}` ? "active" : ""
              }`}
              onClick={() => setActiveType(text2)}
            >
              {text2}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TypeButton;
