import { ResponsivePie } from "@nivo/pie";

const ManequipNivoPieChart2 = ({ text, fontSize }) => (
  <>
    <ResponsivePie
      data={data}
      margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      colors={{ scheme: "blues" }}
      arcLabel={(e) => {
        const { data } = e;
        if (e.id === "") {
          return "N/A";
        }

        return data.value;
      }}
      // arcLinkLabel={false}
      arcLinkLabel={(e) => {
        if (e.id === "") {
          return "";
        }
        return e.id + `(${e.value})`;
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsOffset={-5}
      arcLinkLabelsDiagonalLength={15}
      arcLinkLabelsStraightLength={10}
      arcLinkLabelsThickness={data[0].id === "" ? 0 : 1}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      layers={[
        // 가운데 텍스트 레이어 추가
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        (props) => (
          <CustomCenterLabelLayer {...props} text={text} fontSize={fontSize} />
        ),
        "legends",
      ]}
      // legends={[
      //   {
      //     anchor: "bottom",
      //     direction: "row",
      //     justify: false,
      //     translateX: 0,
      //     translateY: 56,
      //     itemsSpacing: 0,
      //     itemWidth: 100,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     itemDirection: "left-to-right",
      //     itemOpacity: 1,
      //     symbolSize: 18,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
    />
  </>
);

export default ManequipNivoPieChart2;

const data = [
  {
    id: "굴착기계",
    label: "굴착기계",
    value: 2,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "운반차량",
    label: "운반차량",
    value: 1,
    color: "hsl(310, 70%, 50%)",
  },
  {
    id: "양중장비",
    label: "양중장비",
    value: 1,
    color: "hsl(176, 70%, 50%)",
  },
  {
    id: "기타기계",
    label: "기타기계",
    value: 1,
    color: "hsl(39, 70%, 50%)",
  },
  // {
  //   id: "기타",
  //   label: "기타",
  //   value: 3,
  //   color: "hsl(68, 70%, 50%)",
  // },
];

const CustomCenterLabelLayer = ({ centerX, centerY, text, fontSize }) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: fontSize,
        fontWeight: "bold",
        fill: "#333",
      }}
    >
      {text}
    </text>
  );
};
