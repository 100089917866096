import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import styled from "styled-components";
const QualityTable1 = ({ width }) => {
  const [data, setData] = useState(dummyData);
  const [sort, setSort] = useState([]);
  const [newData, setNewData] = useState([]);

  // let dummyData = [
  //   {
  //     title: "Meeting 1",
  //     category: "Work",
  //     cost: 100,
  //     schedule: "-",
  //     date: "2023-01-01",
  //     status: "Open",
  //   },
  //   {
  //     title: "Project Kickoff",
  //     category: "Work",
  //     cost: 200,
  //     schedule: "-",
  //     date: "2023-02-15",
  //     status: "Closed",
  //   },
  //   {
  //     title: "Grocery Shopping",
  //     category: "Personal",
  //     cost: 50,
  //     schedule: "300일",
  //     date: "2023-03-05",
  //     status: "Open",
  //   },
  //   {
  //     title: "Gym Session",
  //     category: "Personal",
  //     cost: 20,
  //     schedule: "1 Hour",
  //     date: "2023-04-10",
  //     status: "Open",
  //   },
  //   {
  //     title: "Family Dinner",
  //     category: "Personal",
  //     cost: 80,
  //     schedule: "2 Hours",
  //     date: "2023-05-20",
  //     status: "Open",
  //   },
  //   {
  //     title: "Client Call",
  //     category: "Work",
  //     cost: 150,
  //     schedule: "1 Hour",
  //     date: "2023-06-08",
  //     status: "Closed",
  //   },
  //   {
  //     title: "Vacation Planning",
  //     category: "Personal",
  //     cost: 0,
  //     schedule: "Full Day",
  //     date: "2023-07-15",
  //     status: "Closed",
  //   },
  //   {
  //     title: "Budget Review",
  //     category: "Work",
  //     cost: 0,
  //     schedule: "2 Hours",
  //     date: "2023-08-05",
  //     status: "Closed",
  //   },
  //   {
  //     title: "Birthday Party",
  //     category: "Personal",
  //     cost: 100,
  //     schedule: "3 Hours",
  //     date: "2023-09-18",
  //     status: "Closed",
  //   },
  //   {
  //     title: "Team Building",
  //     category: "Work",
  //     cost: 300,
  //     schedule: "Half Day",
  //     date: "2023-10-30",
  //     status: "Open",
  //   },
  // ];
  useEffect(() => {
    setNewData(
      dummyData.map((com, idx) => ({ ...com, id: idx, expanded: false }))
    );
  }, []);
  const expandChange = (event) => {
    let new_Data = newData.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setData(new_Data);
  };
  // const expandChange = (event) => {
  //   let new_data = newData.map((item) => {
  //     // console.log(item);
  //     if (item.id === event.dataItem.id) {
  //       item.expanded = !event.dataItem.expanded;
  //     }
  //     return item;
  //   });
  //   setNewData([...new_data]);
  //   console.log(event);
  // };
  return (
    <LessonsLearnedTableDiv>
      <Grid
        style={{
          height: "200px",
        }}
        data={orderBy(data, sort)}
        // data={data}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        detail={DetailComponents}
        expandField="expanded"
        onExpandChange={expandChange}
      >
        <GridColumn
          field="title"
          title="제목"
          // cell={normal_text_cell}
          width={width * 0.3}
        />
        <GridColumn
          field="category"
          title="분류"
          // cell={normal_text_cell}
          width={width * 0.1}
        />
        <GridColumn
          field="cost"
          title="Cost Impact"
          width={width * 0.15}
          // cell={normal_text_cell}
          //   width="20%"
        />
        <GridColumn
          field="schedule"
          title="Schedule Impact"
          width={width * 0.14}
          // cell={noral_text_cell}
          //   width="20%"
        />
        <GridColumn
          field="date"
          title="기한"
          width={width * 0.15}
          // cell={normal_text_cell}
          //   width="20%"
        />
        <GridColumn
          field="status"
          title="상태"
          width={width * 0.1}
          // cell={normal_text_cell}
          //   width="20%"
        />
      </Grid>
    </LessonsLearnedTableDiv>
  );
};

export default QualityTable1;

const DetailComponents = (props) => {
  const dataItem = props.dataItem;
  const { detail_inform, action_plan, promotion_status } = dataItem;

  const markup = (contents) => {
    if (contents) {
      return {
        __html: contents.replaceAll("\\n", "<br />").replaceAll("", "<br />"),
      };
    }
    return { __html: "-" };
  };

  return (
    <DetailComponentsDiv>
      <div style={{ display: "flex" }}>
        <DetailComponentTitleBox>
          상세
          <br />
          설명
        </DetailComponentTitleBox>
        <DetailComponentContentsBox>
          <DetailComponentContents
            dangerouslySetInnerHTML={markup(detail_inform)}
          />
        </DetailComponentContentsBox>
      </div>
      <div style={{ display: "flex", marginTop: "-1px" }}>
        <DetailComponentTitleBox>
          조치
          <br />
          방안
        </DetailComponentTitleBox>
        <DetailComponentContentsBox>
          <DetailComponentContents
            dangerouslySetInnerHTML={markup(action_plan)}
          />
        </DetailComponentContentsBox>
      </div>
      <div style={{ display: "flex", marginTop: "-1px" }}>
        <DetailComponentTitleBox>
          추진
          <br />
          현황
        </DetailComponentTitleBox>
        <DetailComponentContentsBox>
          <DetailComponentContents
            dangerouslySetInnerHTML={markup(promotion_status)}
          />
        </DetailComponentContentsBox>
      </div>
    </DetailComponentsDiv>
  );
};

const LessonsLearnedTableDiv = styled.div`
  .k-header span {
    white-space: normal;
  }

  .k-table-thead {
    /* color: #fff; */

    .k-table-row {
      .k-cell-inner {
        justify-content: center;
      }

      span {
        text-align: center;
      }
    }
  }
`;

const DetailComponentsDiv = styled.div`
  margin: 5px;
  /* color: #fff; */
  font-size: 0.9rem;
  width: 100%;
`;

const DetailComponentTitleBox = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  margin: 0;
  min-width: 50px;
  border: 1px solid lightgray;
  border-right: 0px;
`;

const DetailComponentContentsBox = styled.div`
  height: 100px;
  width: 90%;
  display: flex;
  /* margin: 10px; */
  align-items: center;
  border: 1px solid lightgray;
  /* border-right: 0px; */
  /* padding: 5px; */
`;

const DetailComponentContents = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  white-space: normal;
  overflow-y: auto;
  line-height: 1.7;
`;
let dummyData = [
  {
    title: "태양광 전지 모듈 파손 건",
    category: "Risk",
    cost: "1,100만원",
    schedule: "330일",
    date: "2023-01-01",
    status: "Open",
    detail_inform:
      "- 2021.10.15 ~10.16 양 일간 발생한 우천 및 강풍 (최대 풍속 9.75m/s)으로 수상 작업장 롤링 현상이 발생함 <br />- 이로 인해 작업장 내 작업 중이던 유닛에 슬립현상이 발생하여 진수 후 임시 고정된 유닛과 충돌 및 지속적 마찰 발생하여 기 설치된 모듈이 파손되었고, 기타 육상 및 수상 작업장에 적재된 모듈 일부가 파손.<br/>- 파손 모듈 수량 : 68장",
    action_plan:
      "- 파손된 모듈 및 자재 선별 작업 진행 -> 신규 자재로 교체<br/>- 파손 모듈 및 자재 폐기 처리 및 추가 물량 수급",
    promotion_status:
      "수상 작업장 롤링 현상 우려 상황 시 제품 고박 관련 절차 수립",
  },
  {
    title: "태양광 전지 모듈 파손 건 2차",
    category: "Risk",
    cost: "821백만원",
    schedule: "0일",
    date: "2023-02-15",
    status: "Closed",
    detail_inform: "상세 설명",
    action_plan: "조치 방안",
    promotion_status: "추진 현황",
  },
  // {
  //   title: "Grocery Shopping",
  //   category: "Personal",
  //   cost: 50,
  //   schedule: "300일",
  //   date: "2023-03-05",
  //   status: "Open",
  //   detail_inform: "Purchase groceries for the week.",
  //   action_plan: "Stick to the budget.",
  //   promotion_status: "No promotion planned.",
  // },
  // {
  //   title: "Gym Session",
  //   category: "Personal",
  //   cost: 20,
  //   schedule: "1 Hour",
  //   date: "2023-04-10",
  //   status: "Open",
  //   detail_inform: "Cardio and strength training.",
  //   action_plan: "Follow the workout routine.",
  //   promotion_status: "No promotion planned.",
  // },
  // {
  //   title: "Family Dinner",
  //   category: "Personal",
  //   cost: 80,
  //   schedule: "2 Hours",
  //   date: "2023-05-20",
  //   status: "Open",
  //   detail_inform: "Prepare a special dinner for the family.",
  //   action_plan: "Coordinate with family members for menu.",
  //   promotion_status: "No promotion planned.",
  // },
  // {
  //   title: "Client Call",
  //   category: "Work",
  //   cost: 150,
  //   schedule: "1 Hour",
  //   date: "2023-06-08",
  //   status: "Closed",
  //   detail_inform: "Discuss project updates with the client.",
  //   action_plan: "Address client concerns and provide solutions.",
  //   promotion_status: "Promotion successfully executed.",
  // },
  // {
  //   title: "Vacation Planning",
  //   category: "Personal",
  //   cost: 0,
  //   schedule: "Full Day",
  //   date: "2023-07-15",
  //   status: "Closed",
  //   detail_inform: "Plan itinerary and book accommodations.",
  //   action_plan: "Research and finalize travel arrangements.",
  //   promotion_status: "Promotion successfully executed.",
  // },
  // {
  //   title: "Budget Review",
  //   category: "Work",
  //   cost: 0,
  //   schedule: "2 Hours",
  //   date: "2023-08-05",
  //   status: "Closed",
  //   detail_inform: "Review and analyze financial statements.",
  //   action_plan: "Identify cost-saving opportunities.",
  //   promotion_status: "Promotion successfully executed.",
  // },
  // {
  //   title: "Birthday Party",
  //   category: "Personal",
  //   cost: 100,
  //   schedule: "3 Hours",
  //   date: "2023-09-18",
  //   status: "Closed",
  //   detail_inform: "Plan a surprise birthday party.",
  //   action_plan: "Coordinate with friends for decorations and gifts.",
  //   promotion_status: "Promotion successfully executed.",
  // },
  // {
  //   title: "Team Building",
  //   category: "Work",
  //   cost: 300,
  //   schedule: "Half Day",
  //   date: "2023-10-30",
  //   status: "Open",
  //   detail_inform: "Organize team-building activities.",
  //   action_plan: "Ensure active participation from all team members.",
  //   promotion_status: "No promotion planned.",
  // },
];
