import React from "react";

const ProjectCards4 = ({ width, height }) => {
  return (
    <div>
      <div
        className="card card-flush p-1 me-5 mb-7"
        style={{ minWidth: "300px", width: width, height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">23년 목표 달성 현황</h3>
          </div>
        </div>
        <div className="card-body p-1">
          <div className="card-rounded bg-body position-relative card-px mt-5">
            <div className="row g-0 mb-7">
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold">목표 매출</div>
                <div className="fs-2 fw-bold text-gray-800">50 억</div>
              </div>
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold">실적 매출</div>
                <div className="fs-2 fw-bold text-gray-800">
                  <span className="text-danger">10 억</span>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold">목표 손익</div>
                <div className="fs-2 fw-bold text-gray-800">5 억</div>
              </div>
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold">실적 손익</div>
                <div className="fs-2 fw-bold text-gray-800">
                  <span className="text-danger">3 억</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCards4;
