import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import logo from "../img/hdc_logo.png";
const Bar = styled.div`
  position: sticky;
  min-width: 280px;
  height: 100%;
  background-color: #0d0e12;
  color: #fff;
  /* background-color: black; */
`;
const Logo = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 200px;
  margin-bottom: 50px;
  /* background-color: #131313; */
  /* border-right: 0; */
`;
const Navigator2 = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { type, id, aptid } = useParams();
  const handleResize = () => {
    // 화면 크기가 768px보다 작으면 사이드바를 숨김
    setIsSidebarOpen(window.innerWidth > 768);
  };
  const handleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  useEffect(() => {
    // 처음 렌더링될 때 한 번 실행
    handleResize();

    // 화면 크기가 변할 때마다 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트되면 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const menuData = [
    {
      label: "프로젝트 개요",
      to: `/${type}/${id}/${aptid}`,
    },
    {
      label: "공정률",
      to: `/${type}/${id}/${aptid}/progress`,
    },
    {
      label: "재무현황",
      to: `/${type}/${id}/${aptid}/cost`,
    },
    {
      label: "인원 / 장비",
      to: `/${type}/${id}/${aptid}/manequip`,
    },
    {
      label: "안전",
      to: `/${type}/${id}/${aptid}/safety`,
    },
    {
      label: "품질",
      to: `/${type}/${id}/${aptid}/quality`,
    },
    {
      label: "현장사진",
      to: `/${type}/${id}/${aptid}/photo`,
    },
  ];
  return (
    <>
      <button
        className="app_sidebar_toggle btn btn-icon btn-xl btn-color-black position-absolute"
        style={{ width: "40px", height: "40px", float: "left", zIndex: "99" }}
        onClick={handleSidebar}
      >
        <i
          className="ki-duotone ki-burger-menu-5"
          style={{ fontSize: "20px" }}
        ></i>
      </button>
      {isSidebarOpen && (
        <Bar>
          <Logo>
            <a
              href="/"
              style={{
                margin: "auto",
                marginBottom: 30,
              }}
            >
              <img src={logo} width="150" height="50" alt="" />
            </a>

            <h4 className="column fs-1 text-white w-bolder mx-auto">{aptid}</h4>
          </Logo>
          <nav style={{ color: "white" }}>
            <ul>
              {menuData.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </ul>
          </nav>
          {/* <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              navigate(itemId);
            }}
            items={[
              {
                title: "프로젝트 개요",
                itemId: `/${id}/${aptid}`,
              },
              {
                title: "공정률",
                itemId: `/${id}/${aptid}/progress`,
              },
              {
                title: "재무현황",
                itemId: `/${id}/${aptid}/cost`,
              },
              {
                title: "인원 / 장비",
                itemId: `/${id}/${aptid}/manequip`,
              },
              {
                title: "안전",
                itemId: `/${id}/${aptid}/safety`,
              },
              {
                title: "품질",
                itemId: `/${id}/${aptid}/quality`,
              },
            ]}
          /> */}
        </Bar>
      )}
    </>
  );
};

export default Navigator2;
const MenuItem = ({ label, to, subMenu, depth = 0 }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname === to;
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const fontSize = 16 - depth * 3; // Adjust the font size based on the depth

  return (
    <li style={{ listStyle: "none" }}>
      <div
        onClick={toggleSubMenu}
        style={{
          cursor: "pointer",
          marginBottom: "30px",
          fontWeight: "normal", // Set default font weight
        }}
      >
        <Link
          style={{
            color: "white",
            fontSize: `${fontSize}px`,
            textDecoration: "none",
            fontWeight: "normal", // Set default font weight
            ...(isSelected && { fontWeight: "bold" }), // Apply bold style if isSelected
          }}
          to={to}
        >
          {label}
        </Link>
        {subMenu && subMenu.length > 0 && (
          <span>{isSubMenuOpen ? " -" : " +"}</span>
        )}
      </div>
      {isSubMenuOpen && subMenu && subMenu.length > 0 && (
        <ul style={{}}>
          {subMenu.map((item, index) => (
            <MenuItem key={index} {...item} depth={depth + 1} />
          ))}
        </ul>
      )}
    </li>
  );
};
