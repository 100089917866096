import React from "react";

const TotalPeriodButton2 = ({ activePeriod, setActivePeriod }) => {
  return (
    <>
      <div className="card-toolbar me-5" data-kt-buttons="true">
        <ul className="nav">
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activePeriod === "월간" ? "active" : ""
              }`}
              onClick={() => setActivePeriod("월간")}
            >
              월간
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn btn-sm text-muted btn-active btn-active-light fw-bold px-4 me-1 ${
                activePeriod === "연간" ? "active" : ""
              }`}
              onClick={() => setActivePeriod("연간")}
            >
              연간
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TotalPeriodButton2;
