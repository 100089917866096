import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import styled from "styled-components";
const QualityTabel2 = ({ width }) => {
  const [sort, setSort] = useState([]);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(
      dummyData.map((com, idx) => ({ ...com, id: idx, expanded: false }))
    );
  }, []);
  console.log(newData);

  return (
    <VenderRankingTableDiv>
      <Grid
        data={orderBy(newData, sort)}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridColumn field="name" title="업체명" width={width * 0.2} />
        <GridColumn title="총 계약금액">
          <GridColumn
            field="cost"
            title="금액"
            width={width * 0.1}
            cell={(props) => (
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  marginTop: "5px",
                  zIndex: "2",
                }}
              >
                {props.dataItem.cost_percent > 0 && ( // 수정된 부분
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: `rgba(0, 123, 255)`,
                      padding: "5px",
                      borderRadius: "3px",
                      opacity: "0.1",
                      width: `${props.dataItem.cost_percent}%`,
                      height: "100%",
                      zIndex: "1",
                    }}
                  ></div>
                )}
                {props.dataItem.cost}
              </div>
            )}
          />
          <GridColumn field="cost_percent" title="비율" width={width * 0.07} />
        </GridColumn>
        <GridColumn field="avl" title="AVL 평가점수" width={width * 0.1} />
        <GridColumn title="검사불합격수">
          <GridColumn
            field="inspection"
            title="불합격수"
            width={width * 0.1}
            cell={(props) => (
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  marginTop: "5px",
                  zIndex: "2",
                }}
              >
                {props.dataItem.inspection_percent > 0 && ( // 수정된 부분
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: `rgba(0, 123, 255)`,
                      padding: "5px",
                      borderRadius: "3px",
                      opacity: "0.1",
                      width: `${props.dataItem.inspection_percent}%`,
                      height: "100%",
                      zIndex: "1",
                    }}
                  ></div>
                )}
                {props.dataItem.inspection}
              </div>
            )}
          />
          <GridColumn
            field="inspection_percent"
            title="비율"
            width={width * 0.07}
          />
        </GridColumn>

        <GridColumn title="부적합/OP">
          <GridColumn
            field="unsuitable"
            title="부적합/OP"
            width={width * 0.1}
            cell={(props) => (
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  marginTop: "5px",
                  zIndex: "2",
                }}
              >
                {props.dataItem.unsuitable_percent > 0 && ( // 수정된 부분
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: `rgba(0, 123, 255)`,
                      padding: "5px",
                      borderRadius: "3px",
                      opacity: "0.1",
                      width: `${props.dataItem.unsuitable_percent}%`,
                      height: "100%",
                      zIndex: "1",
                    }}
                  ></div>
                )}
                {props.dataItem.unsuitable}
              </div>
            )}
          />
          <GridColumn
            field="unsuitable_percent"
            title="비율"
            width={width * 0.07}
          />
        </GridColumn>
        <GridColumn
          field="issue"
          title="품질이슈조치일수"
          width={width * 0.1}
        />
      </Grid>
    </VenderRankingTableDiv>
  );
};

export default QualityTabel2;
const VenderRankingTableDiv = styled.div`
  .k-header span {
    white-space: normal;
  }

  .k-table-thead {
    /* color: #fff; */

    .k-table-row {
      .k-cell-inner {
        justify-content: center;
      }

      span {
        text-align: center;
      }
    }
  }
`;

let dummyData = [
  {
    name: "업체 1",
    cost: "70.1억",
    cost_percent: 50,
    avl: 15,
    inspection: 5,
    inspection_percent: 50,
    unsuitable: 2,
    unsuitable_percent: 20,
    issue: 1,
  },
  {
    name: "업체 2",
    cost: "37.1억",
    cost_percent: 30,
    avl: 12,
    inspection: 6,
    inspection_percent: 60,
    unsuitable: 3,
    unsuitable_percent: 30,
    issue: 2,
  },
  {
    name: "업체 3",
    cost: "0.0억",
    cost_percent: 0,
    avl: 18,
    inspection: 4,
    inspection_percent: 40,
    unsuitable: 1,
    unsuitable_percent: 10,
    issue: 0,
  },
  {
    name: "업체 4",
    cost: "140.3억",
    cost_percent: 100,
    avl: 10,
    inspection: 8,
    inspection_percent: 80,
    unsuitable: 4,
    unsuitable_percent: 40,
    issue: 3,
  },
  {
    name: "업체 5",
    cost: "100.1억",
    cost_percent: 22.5,
    avl: 20,
    inspection: 2,
    inspection_percent: 20,
    unsuitable: 1,
    unsuitable_percent: 10,
    issue: 1,
  },
];

// const CostCell = styled.div`
//   position: "relative";
//   width: "100%";
//   // backgroundColor: "aqua",
//   height: "100%";
//   margin-top: "5px";
//   z-index: "2";
// `;

// const CostBackground = styled.div`
//   /* position: absolute;
//   background-color: ${(props) =>
//     `rgba(0, 123, 255, ${props.data.cost_percent / 100})`};
//   width: ${(props) => props.data.cost_percent}%;
//   padding: 5px;
//   border-radius: 3px;
//   text-align: center;
//   z-index: 1; */
//   position: "absolute";
//   background-color: "#8ef";
//   padding: "5px";
//   border-radius: "3px";
//   opacity: "0.3";
//   width: ${props.dataItem.cost_percent}%;
//   height: "100%";
//   z-index: "1";
// `;
