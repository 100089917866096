import React, { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper/modules";
import Zoom from "react-medium-image-zoom";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "react-medium-image-zoom/dist/styles.css";
import PhotoCard from "./PhotoCard";

const ApartImage = ({ imageList, width, height }) => {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <ProjectPhotoDiv>
      <div style={{ display: "flex" }}>
        <div style={{ width: "60%" }}>
          <div style={{ width: width * 0.6, height: (height / 4) * 3 }}>
            <Swiper
              thumbs={{ swiper: imagesNavSlider }}
              direction="horizontal"
              slidesPerView={1}
              navigation={true}
              spaceBetween={10}
              mousewheel={true}
              className="swiper-container2"
              modules={[Navigation, Thumbs, Mousewheel, FreeMode]}
              style={{
                height: (height / 4) * 3,
              }}
              effect="fade"
            >
              {imageList.map((c, i) => {
                const { image } = c;
                return (
                  <SwiperSlide key={i}>
                    <SildeContentsBox>
                      <Zoom>
                        <SlideImg2
                          src={image}
                          alt="project_img"
                          tile_height={(height / 4) * 3}
                        />
                      </Zoom>
                    </SildeContentsBox>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div
            style={{
              width: width * 0.6,
              height: height / 4,
              marginRight: "5px",
            }}
          >
            <Swiper
              onSwiper={setImagesNavSlider}
              direction="horizontal"
              spaceBetween={4}
              slidesPerView={4}
              modules={[FreeMode, Navigation, Thumbs]}
              className="swiper-container1"
              style={{ height: height / 4, position: "relative" }}
              effect="fade"
            >
              {imageList.map((c, i) => {
                const { image } = c;
                return (
                  <SwiperSlide key={i}>
                    <SlideImg
                      src={image}
                      alt="project_img"
                      onClick={(swiper) => setSelectedImageIndex(i)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div style={{ width: "40%", padding: "20px" }}>
          <PhotoCard />
        </div>
      </div>
    </ProjectPhotoDiv>
  );
};

export default ApartImage;
const SildeContentsBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
const ProjectPhotoDiv = styled.div`
  /* display: "flex"; */
  .swiper-container1 .swiper-slide {
    opacity: 0.4;
  }

  .swiper-container1 .swiper-slide:hover {
    opacity: 1 !important;
  }

  .swiper-container1 .swiper-slide-thumb-active {
    opacity: 1 !important;
  }
`;

const SlideImg = styled.img`
  height: 100%;

  width: 100%;

  object-fit: cover;
`;

const SlideImg2 = styled.img`
  height: ${({ tile_height }) => tile_height}px;
  /* height: 100%; */

  width: 100%;

  object-fit: cover;
`;
