import React from "react";

const SafetyStatus = () => {
  return (
    <>
      <div className="flex-grow-1 ">
        <p className="text-dark fw-bold fs-4">
          ■ 폭염으로 온열질환 의심환자 발생
        </p>
        <div className="py-3 ms-7">
          <p>- 일시 : 8월 19일 (오전 10:20) / 현장온도 : 32도</p>
          <p>- 소속 : 협력사1(조립공) / 나이 : 45세</p>
          <p>
            - 현장 온열지킴이 현장 순찰중 38도 이상 근로자 발견 보건실로 이송
          </p>
          <p>
            - 건강상태 체크 : 혈압 183/126 , 어지럼증이나, 구토 등 이상 증상은
            없음
          </p>
          <p>
            - 보건실 휴식 후 귀가 조치(체온은 36.8로 내려갔으나, 혈압이
            165/109으로 고혈압 유지)
          </p>
        </div>
      </div>
    </>
  );
};

export default SafetyStatus;
