import React from "react";

const ProjectCards5 = ({ width, height }) => {
  return (
    <div>
      <div
        className="card card-flush p-1 me-5 mb-7"
        style={{ width: width, height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">공정률</h3>
          </div>
        </div>
        <div className="card-body p-1">
          <div
            className="card-rounded bg-body position-relative card-px"
            style={{ justifyContent: "space-between" }}
          >
            <div className="row g-0 mb-7" style={{ textAlign: "center" }}>
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold my-1">Plan</div>
                <div className="fs-1 fw-bold text-gray-800">98.80 %</div>
              </div>
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold my-1">Actual</div>
                <div className="fs-1 fw-bold text-gray-800">98.64 %</div>
              </div>
            </div>
            <div className="row g-0" style={{ textAlign: "center" }}>
              <div className="col mx-5">
                <div className="fs-6 text-gray-500 fw-bold my-1">Dev.</div>
                <div className="fs-1 fw-bold text-danger">- 0.16 %</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCards5;
