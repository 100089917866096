import React, { useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import image7 from "../image7.jpg";
import image11 from "../image11.jpg";
import image12 from "../image12.jpg";
import image13 from "../image13.jpg";
import image14 from "../image14.jpeg";
import ApartImage from "./ApartImage";
import ApartImage2 from "./ApartImage2";

const ApartPhoto = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const imageList = [
    { image: image12 },
    // { image: image7 },
    { image: image13 },
    { image: image14 },
    { image: image11 },
  ];
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          //   display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: ((height - 100) / 3) * 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">CCTV</h3>
              </div>
            </div>
            <div className="card-body p-1" style={{ display: "flex" }}>
              <img
                src={image7}
                alt=""
                style={{ width: "90%", height: "90%", margin: "auto" }}
              />
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: ((height - 100) / 3) * 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">주요공정 Weekly</h3>
              </div>
            </div>
            <div className="card-body p-7">
              <ApartImage
                width={
                  isSidebarOpen
                    ? ((width - 80 - 280) / 5) * 3 - 60
                    : ((width - 80) / 5) * 3 - 60
                }
                height={((height - 100) / 3) * 2 - 120}
                imageList={imageList}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen ? width - 80 - 280 : width - 80,
              height: (height - 100) / 3,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Photo History</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <ApartImage2 height={(height - 100) / 3 - 26 - 60} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartPhoto;
