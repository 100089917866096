import React from "react";

const TotalTable = () => {
  return (
    <>
      <p style={{ marginBottom: "0", textAlign: "center" }}>
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>7</span>
        <span style={{ fontSize: "16px", fontWeight: "bold" }}> Events</span>
      </p>
      <div
        style={{
          height: "40%",
          overflow: "scroll",
          marginBottom: "10px",
          fontSize: "12px",
        }}
      >
        <table className="table table-row-dashed table-row-gray-300">
          <thead
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "#fff",
            }}
          >
            <tr
              className="fw-bolder text-gray-800"
              style={{ fontSize: "12px" }}
            >
              <th style={{ width: "15%" }}>계획일</th>
              <th style={{ width: "15%" }}>실적일</th>
              <th style={{ width: "20%" }}>프로젝트</th>
              <th>내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12-01 </td>
              <td>-</td>
              <td>Project 1</td>
              <td>-</td>
            </tr>
            <tr>
              <td>12-01 </td>
              <td>12-10</td>
              <td>Project 2</td>
              <td>-</td>
            </tr>
            <tr>
              <td>12-01 </td>
              <td>12-05</td>
              <td>Project 3</td>
              <td>-</td>
            </tr>
            <tr>
              <td>12-05 </td>
              <td>-</td>
              <td>Project 4</td>
              <td>-</td>
            </tr>
            <tr>
              <td>12-14 </td>
              <td>12-14</td>
              <td>Project 5</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TotalTable;
