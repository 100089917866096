import React from "react";

const InfoLine = ({ title, contents }) => {
  return (
    <div className="row mb-7">
      <label
        className="col-lg-4 fw-semibold text-gray-500 "
        style={{ minWidth: "90px" }}
      >
        {title}
      </label>
      <div className="col-lg-8 fv-row">
        <span className="fw-bold text-gray-800 fs-6">{contents}</span>
      </div>
    </div>
  );
};

export default InfoLine;
