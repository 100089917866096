import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_southKoreaLow from "@amcharts/amcharts5-geodata/southKoreaLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useParams } from "react-router-dom";
am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");
const TotalMap2 = () => {
  const { type, id, aptid } = useParams();
  useEffect(() => {
    var root = am5.Root.new("chartdiv2");

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "translateY",
        projection: am5map.geoMercator(),
        // homeGeoPoint: { longitude: 128.483146, latitude: 35.821151 },
      })
    );

    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_southKoreaLow,
        exclude: ["AQ"],
        fill: am5.color(0xe5e5e5),
      })
    );

    var previousPolygon;

    polygonSeries.mapPolygons.template.on("active", function (active, target) {
      if (previousPolygon && previousPolygon != target) {
        previousPolygon.set("active", false);
      }
      if (target.get("active")) {
        polygonSeries.zoomToDataItem(target.dataItem);
      } else {
        chart.goHome();
      }
      previousPolygon = target;
    });
    polygonSeries.events.on("datavalidated", function () {
      chart.goHome();
    });

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
    });
    let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    let colorset = am5.ColorSet.new(root, {});

    pointSeries.bullets.push(function () {
      let container = am5.Container.new(root, {
        tooltipText: "{region}: {project}",
        // cursorOverStyle: "pointer",
      });

      // container.events.on("click", (e) => {
      //   window.location.href = e.target.dataItem.dataContext.url;
      // });

      let circle = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: colorset.next(),
          strokeOpacity: 0,
        })
      );

      container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: colorset.next(),
          strokeOpacity: 0,
          tooltipText: "{title}",
        })
      );

      // let circle2 = container.children.push(
      //   am5.Circle.new(root, {
      //     radius: 4,
      //     tooltipY: 0,
      //     fill: colorset.next(),
      //     strokeOpacity: 0,
      //     tooltipText: "{title}",
      //   })
      // );

      circle.animate({
        key: "scale",
        from: 1,
        to: 5,
        duration: 5000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });

      circle.animate({
        key: "opacity",
        from: 1,
        to: 0.1,
        duration: 5000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });
    let filteredCities =
      id !== undefined
        ? aptid !== undefined
          ? cities
              .filter((city) => city.region === id)
              .filter((c) => c.project === aptid)
          : cities.filter((city) => city.region === id)
        : cities;
    if (type === "dev") {
      filteredCities.filter(
        (c) => c.project === "apt1" || c.project === "apt2"
      );
    } else if (type === "order") {
      filteredCities.filter(
        (c) => c.project === "apt3" || c.project === "apt4"
      );
    }
    if (id !== undefined) {
      chart.set("homeGeoPoint", {
        longitude: filteredCities[0].longitude,
        latitude: filteredCities[0].latitude,
      });
      chart.set("homeZoomLevel", 2);
    }
    console.log(filteredCities[0]);
    for (let i = 0; i < filteredCities.length; i++) {
      let city = filteredCities[i];
      addCity(city.longitude, city.latitude, city.region, city.project);
    }
    function addCity(longitude, latitude, region, project) {
      pointSeries.data.push({
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        region,
        project,
      });
    }
    // Make stuff animate on load
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [aptid, id, type]);
  return <div id="chartdiv2" style={{ height: "180px" }}></div>;
};

export default TotalMap2;
const cities = [
  {
    longitude: 126.978,
    latitude: 37.5665,
    region: "seoul",
    name: "서울",
    project: "apt1",
  },
  {
    longitude: 126.985,
    latitude: 37.5665,
    region: "seoul",
    name: "서울",
    project: "apt2",
  },
  {
    longitude: 126.99,
    latitude: 37.57,
    region: "seoul",
    name: "서울",
    project: "apt3",
  },
  {
    longitude: 126.995,
    latitude: 37.575,
    region: "seoul",
    name: "서울",
    project: "apt4",
  },
  {
    longitude: 127.0067,
    latitude: 37.4563,
    region: "gyeonggido",
    name: "경기도",
    project: "apt1",
  },
  {
    longitude: 127.0167,
    latitude: 37.4563,
    region: "gyeonggido",
    name: "경기도",
    project: "apt2",
  },
  {
    longitude: 127.021,
    latitude: 37.46,
    region: "gyeonggido",
    name: "경기도",
    project: "apt3",
  },
  {
    longitude: 127.026,
    latitude: 37.465,
    region: "gyeonggido",
    name: "경기도",
    project: "apt4",
  },
  {
    longitude: 127.6895,
    latitude: 35.8961,
    region: "gangwondo",
    name: "강원도",
    project: "apt1",
  },
  {
    longitude: 128.6635,
    latitude: 35.7988,
    region: "gangwondo",
    name: "강원도",
    project: "apt2",
  },
  {
    longitude: 128.669,
    latitude: 35.804,
    region: "gangwondo",
    name: "강원도",
    project: "apt3",
  },
  {
    longitude: 128.674,
    latitude: 35.809,
    region: "gangwondo",
    name: "강원도",
    project: "apt4",
  },
  {
    longitude: 127.9206,
    latitude: 36.6357,
    region: "chungcheongbukdo",
    name: "충청북도",
    project: "apt1",
  },
  {
    longitude: 126.835,
    latitude: 36.4437,
    region: "chungcheongbukdo",
    name: "충청북도",
    project: "apt2",
  },
  {
    longitude: 126.84,
    latitude: 36.448,
    region: "chungcheongbukdo",
    name: "충청북도",
    project: "apt3",
  },
  {
    longitude: 126.845,
    latitude: 36.453,
    region: "chungcheongbukdo",
    name: "충청북도",
    project: "apt4",
  },
  {
    longitude: 126.4631,
    latitude: 35.5436,
    region: "chungcheongnamdo",
    name: "충청남도",
    project: "apt1",
  },
  {
    longitude: 127.5466,
    latitude: 36.6727,
    region: "chungcheongnamdo",
    name: "충청남도",
    project: "apt2",
  },
  {
    longitude: 127.552,
    latitude: 36.678,
    region: "chungcheongnamdo",
    name: "충청남도",
    project: "apt3",
  },
  {
    longitude: 127.557,
    latitude: 36.683,
    region: "chungcheongnamdo",
    name: "충청남도",
    project: "apt4",
  },
  {
    longitude: 127.3372,
    latitude: 35.7167,
    region: "jeollabukdo",
    name: "전라북도",
    project: "apt1",
  },
  {
    longitude: 126.9945,
    latitude: 35.7175,
    region: "jeollabukdo",
    name: "전라북도",
    project: "apt2",
  },
  {
    longitude: 127.0,
    latitude: 35.72,
    region: "jeollabukdo",
    name: "전라북도",
    project: "apt3",
  },
  {
    longitude: 127.005,
    latitude: 35.725,
    region: "jeollabukdo",
    name: "전라북도",
    project: "apt4",
  },
  {
    longitude: 126.977,
    latitude: 34.816,
    region: "jeollanamdo",
    name: "전라남도",
    project: "apt1",
  },
  {
    longitude: 127.4626,
    latitude: 34.8148,
    region: "jeollanamdo",
    name: "전라남도",
    project: "apt2",
  },
  {
    longitude: 127.468,
    latitude: 34.82,
    region: "jeollanamdo",
    name: "전라남도",
    project: "apt3",
  },
  {
    longitude: 127.473,
    latitude: 34.825,
    region: "jeollanamdo",
    name: "전라남도",
    project: "apt4",
  },
  {
    longitude: 129.0756,
    latitude: 35.1605,
    region: "gyeongsangbukdo",
    name: "경상북도",
    project: "apt1",
  },
  {
    longitude: 128.4885,
    latitude: 35.8703,
    region: "gyeongsangbukdo",
    name: "경상북도",
    project: "apt2",
  },
  {
    longitude: 128.494,
    latitude: 35.876,
    region: "gyeongsangbukdo",
    name: "경상북도",
    project: "apt3",
  },
  {
    longitude: 128.499,
    latitude: 35.881,
    region: "gyeongsangbukdo",
    name: "경상북도",
    project: "apt4",
  },
  {
    longitude: 128.6507,
    latitude: 35.2376,
    region: "gyeongsangnamdo",
    name: "경상남도",
    project: "apt1",
  },
  {
    longitude: 128.0847,
    latitude: 35.2439,
    region: "gyeongsangnamdo",
    name: "경상남도",
    project: "apt2",
  },
  {
    longitude: 128.09,
    latitude: 35.249,
    region: "gyeongsangnamdo",
    name: "경상남도",
    project: "apt3",
  },
  {
    longitude: 128.095,
    latitude: 35.254,
    region: "gyeongsangnamdo",
    name: "경상남도",
    project: "apt4",
  },
  {
    longitude: 126.5456,
    latitude: 33.3797,
    region: "jejudo",
    name: "제주도",
    project: "apt1",
  },
  {
    longitude: 126.9062,
    latitude: 33.489,
    region: "jejudo",
    name: "제주도",
    project: "apt2",
  },
  {
    longitude: 126.912,
    latitude: 33.495,
    region: "jejudo",
    name: "제주도",
    project: "apt3",
  },
  {
    longitude: 126.917,
    latitude: 33.5,
    region: "jejudo",
    name: "제주도",
    project: "apt4",
  },
];
