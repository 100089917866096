import React from "react";

const TotalSafetyStatus2 = () => {
  return (
    <>
      <div
        style={{
          width: "50%",
          margin: "auto 0",
          textAlign: "center",
        }}
      >
        <table className="table table-row-dashed table-row-gray-300">
          <tbody style={{ fontSize: "14px" }}>
            <tr>
              <td style={{ fontWeight: "bold" }}>Industrial</td>
              <td>
                <span style={{ color: "orange" }}>12</span> 개 / 25 개
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Gas & Power</td>
              <td>
                <span style={{ color: "orange" }}>9</span> 개 / 52 개
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>수소친환경</td>
              <td>
                <span style={{ color: "orange" }}>83</span> 개 / 256 개
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TotalSafetyStatus2;
