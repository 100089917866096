import React, { useState } from "react";
import Navigator from "../../components/Navigator";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import TotalStackChart from "./TotalStackChart";
import TotalTable from "./TotalTable";
import TotalLTIRTable from "./TotalLTIRTable";
import TotalSafetyStatus1 from "./TotalSafetyStatus1";
import TotalSafetyStatus2 from "./TotalSafetyStatus2";
import TotalProgressChart from "./TotalProgressChart";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import TotalMap2 from "./TotalMap2";
import TotalCostChart from "./TotalCostChart";
import TotalPQI from "./TotalPQI";
import TotalPeriodButton from "./TotalPeriodButton";
import MyResponsivePie from "../../components/nivoPieChart";
import TotalPeriodButton2 from "./TotalPeriodButton2";
import TotalTypeButton from "./TotalTypeButton";
import TotalKPIChart from "./TotalKPIChart";
import TotalTable2 from "./TotalTable2";

const TotalPage = () => {
  const { width, height } = useWindowDimensions();
  const { type, id } = useParams();
  const [activePeriod1, setActivePeriod1] = useState("day");
  const [activePeriod2, setActivePeriod2] = useState("월간");
  const [activeType, setActiveType] = useState("매출");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Navigator
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          // display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
          overflow: "scroll",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            className="card card-flush mb-7"
            style={{
              width: isSidebarOpen ? width - 80 - 280 : width - 80,
              height: (height - 100) / 3 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Project Info.</h3>
              </div>
            </div>
            <div className="card-body pt-0" style={{ display: "flex" }}>
              <div className="me-5" style={{ width: "33%" }}>
                {/* <TotalMap /> */}
                <TotalMap2 />
              </div>
              <div className="me-5" style={{ width: "33%" }}>
                <b>Progress</b>
                <TextCenterDiv>
                  <ContentsCenterDiv>
                    <BehindNumber>{id !== undefined ? 1 : 5}</BehindNumber>
                    <BehindText>&nbsp; Behind</BehindText>
                  </ContentsCenterDiv>
                  <ContentsCenterDiv>
                    <AHeadNumber>{id !== undefined ? 1 : 5}</AHeadNumber>
                    <AHeadText>&nbsp; Ahead</AHeadText>
                  </ContentsCenterDiv>
                </TextCenterDiv>
                <div
                  style={{
                    // marginLeft: "20px",
                    // marginTop: "20px",
                    height: "130px",
                    overflow: "scroll",
                  }}
                >
                  {id !== undefined ? (
                    <>
                      <TotalProgressChart
                        id="total1"
                        title={type === "dev" ? "Apt 1" : "Apt 3"}
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total2"
                        title={type === "dev" ? "Apt 2" : "Apt 4"}
                        percent={80}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                    </>
                  ) : (
                    <>
                      <TotalProgressChart
                        id="total1"
                        title="서 울"
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total2"
                        title="경기도"
                        percent={80}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total3"
                        title="강원도"
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total4"
                        title="충청북도"
                        percent={70}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total5"
                        title="충청남도"
                        percent={90}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total6"
                        title="전라북도"
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total7"
                        title="전라남도"
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total8"
                        title="경상북도"
                        percent={100}
                        width="280px"
                        color="#172A4B"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total9"
                        title="경상남도"
                        percent={70}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                      <TotalProgressChart
                        id="total10"
                        title="제주도"
                        percent={10}
                        width="280px"
                        color="#f1415c"
                        height="35px"
                        marginBottom={0}
                      />
                    </>
                  )}
                </div>
              </div>
              {type === undefined ? (
                <div style={{ width: "34%" }}>
                  <b style={{ marginBottom: "10px" }}>개발 프로젝트 현황</b>
                  <TotalTable2 />
                </div>
              ) : (
                <div style={{ width: "34%" }}>
                  <b>Key Events</b>
                  <TotalTable />
                </div>
              )}
            </div>
            {/* <CostChart1 id="id2" /> */}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div
              className="card card-flush me-5 mb-7"
              style={{
                width: isSidebarOpen
                  ? (width - 80 - 280) / 3
                  : (width - 80) / 3,
                height: (height - 100) / 3 - 10,
              }}
            >
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Construction</h3>
                </div>
              </div>
              <div className="card-body py-2 ">
                {/* <CostChart1 id="tt" /> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    인원 투입현황
                    <span style={{ fontSize: "10px" }}> (작업일보 기준)</span>
                  </span>
                  <TotalPeriodButton
                    activePeriod={activePeriod1}
                    setActivePeriod={setActivePeriod1}
                  />
                </div>

                <TotalStackChart
                  data={data1}
                  id="totalstack1"
                  height="160px"
                  fontSize="0.7rem"
                />
              </div>
            </div>
            <div
              className="card card-flush me-5 mb-7"
              style={{
                width: isSidebarOpen
                  ? (width - 80 - 280) / 3
                  : (width - 80) / 3,
                height: (height - 100) / 3 - 10,
              }}
            >
              <div className="card-header">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Quality & Environment</h3>
                </div>
              </div>
              <div className="card-body py-2">
                <div style={{ height: "100%" }}>
                  <p style={{ marginBottom: "0" }}>
                    <b>PQI</b>
                  </p>
                  {/* <QualityTable /> */}
                  <TotalPQI />
                </div>
              </div>
            </div>
          </div>

          <div
            className="card card-flush mb-7 "
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 3) * 2 - 10
                : ((width - 80) / 3) * 2 - 10,
              height: ((height - 100) / 3) * 2,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Cost</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 30px",
              }}
            >
              <TotalTypeButton
                activeType={activeType}
                setActiveType={setActiveType}
              />
              <TotalPeriodButton2
                activePeriod={activePeriod2}
                setActivePeriod={setActivePeriod2}
              />
            </div>
            <div className="card-body py-2" style={{ display: "flex" }}>
              <TotalCostChart chart_id="totalcost" data={data2} text1=" 억" />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5  mb-7"
            style={{
              width: isSidebarOpen ? (width - 80 - 280) / 3 : (width - 80) / 3,
              height: ((height - 100) / 3) * 2,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Safety</h3>
              </div>
            </div>
            <div className="card-body py-2">
              <div style={{ width: "100%" }}>
                <b>LTIR</b>
                <div style={{ display: "flex", marginBottom: "30px" }}>
                  <div
                    style={{
                      width: "40%",
                      margin: "auto 0",
                      textAlign: "center",
                      overflow: "scroll",
                      height: "150px",
                    }}
                  >
                    <TotalLTIRTable />
                  </div>
                  <div
                    style={{
                      width: "60%",
                      margin: "auto 0",
                      textAlign: "center",
                      height: "200px",
                    }}
                  >
                    {/* <ManequipNivoPieChart text="21" /> */}
                    <div
                      style={{
                        width: "220px",
                        height: "200px",
                        margin: "auto ",
                        padding: "10px",
                      }}
                    >
                      <MyResponsivePie data={data} />
                      <b
                        style={{
                          fontSize: "11px",
                          position: "relative",
                          top: "-15px",
                        }}
                      >
                        유형별 LTI (연단위 누적)
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <b>
                  위험 작업 현황
                  <span style={{ fontSize: "10px" }}>(작업허가서 기준)</span>
                </b>
                <div
                  style={{ width: "100%", display: "flex", paddingTop: "30px" }}
                >
                  <TotalSafetyStatus1 />
                  <TotalSafetyStatus2 />
                </div>
              </div>
            </div>
          </div>
          <div
            className="card card-flush mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 3) * 2 - 10
                : ((width - 80) / 3) * 2 - 10,
              height: ((height - 100) / 3) * 2,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">프로젝트별 KPI</h3>
              </div>
            </div>
            <div className="card-body py-2">
              <TotalKPIChart
                data={id === undefined ? kpidata1 : kpidata2}
                height="500px"
                objList={
                  id === undefined
                    ? Object.keys(kpidata1)
                    : Object.keys(kpidata2)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalPage;
const TextCenterDiv = styled.div`
  display: flex;
  margin-left: 150px;
  margin-bottom: 10px;
  /* align-items: center; */
  /* height: 100%; */
  /* justify-content: center; */
`;
const ContentsCenterDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 10px;
`;
const BehindNumber = styled.div`
  color: #dd4132;
  font-size: 1.2rem;
  font-weight: bold;
`;
const BehindText = styled.div`
  color: #dd4132;
  font-size: 0.9rem;
`;
const AHeadNumber = styled.div`
  color: #a0daa9;
  font-size: 1.2rem;
  font-weight: bold;
`;
const AHeadText = styled.div`
  color: #a0daa9;
  font-size: 0.9rem;
`;
var data1 = [
  {
    year: "12-01",
    관리자: 12,
    직영근로자수: 15,
    하수급관리자수: 20,
    하수급근로자수: 7,
  },
  {
    year: "12-02",
    관리자: 16,
    직영근로자수: 27,
    하수급관리자수: 22,
    하수급근로자수: 5,
  },
  {
    year: "12-03",
    관리자: 2,
    직영근로자수: 9,
    하수급관리자수: 19,
    하수급근로자수: 13,
  },
  {
    year: "12-04",
    관리자: 18,
    직영근로자수: 9,
    하수급관리자수: 14,
    하수급근로자수: 3,
  },
  {
    year: "12-05",
    관리자: 10,
    직영근로자수: 9,
    하수급관리자수: 11,
    하수급근로자수: 23,
  },
  {
    year: "12-06",
    관리자: 2,
    직영근로자수: 3,
    하수급관리자수: 10,
    하수급근로자수: 20,
  },
  {
    year: "12-07",
    관리자: 6,
    직영근로자수: 15,
    하수급관리자수: 4,
    하수급근로자수: 15,
  },
  {
    year: "12-08",
    관리자: 8,
    직영근로자수: 9,
    하수급관리자수: 4,
    하수급근로자수: 3,
  },
  {
    year: "12-09",
    관리자: 2,
    직영근로자수: 9,
    하수급관리자수: 11,
    하수급근로자수: 13,
  },
];
const data = [
  {
    id: "낙하",
    label: "낙하",
    value: 4,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "전도",
    label: "전도",
    value: 5,
    color: "hsl(310, 70%, 50%)",
  },
  {
    id: "협착",
    label: "협착",
    value: 2,
    color: "hsl(176, 70%, 50%)",
  },
  {
    id: "충돌",
    label: "충돌",
    value: 7,
    color: "hsl(39, 70%, 50%)",
  },
  {
    id: "기타",
    label: "기타",
    value: 3,
    color: "hsl(68, 70%, 50%)",
  },
];
var data2 = [
  {
    year: "2023-01",
    act: 319.12,
    plan: 475.12,
    cum_act: 319.12,
    cum_plan: 475.12,
  },
  {
    year: "2023-02",
    act: 325.05,
    plan: 456.52,
    cum_act: 644.22,
    cum_plan: 931.64,
  },
  {
    year: "2023-03",
    act: 407.24,
    plan: 491.8,
    cum_act: 1051.46,
    cum_plan: 1423.44,
  },
  {
    year: "2023-04",
    act: 500.21,
    plan: 375.57,
    cum_act: 1551.67,
    cum_plan: 1799.01,
  },
  {
    year: "2023-05",
    act: 424.22,
    plan: 283.51,
    cum_act: 1975.88,
    cum_plan: 2082.53,
  },
  {
    year: "2023-06",
    act: 452.11,
    plan: 468.16,
    cum_act: 2427.99,
    cum_plan: 2550.69,
  },
  {
    year: "2023-07",
    act: 487.14,
    plan: 428.69,
    cum_act: 2915.13,
    cum_plan: 2979.37,
  },
  {
    year: "2023-08",
    act: 173.52,
    plan: 384.44,
    cum_act: 3088.65,
    cum_plan: 3363.81,
  },
  {
    year: "2023-09",
    act: 235.04,
    plan: 444.59,
    cum_act: 3323.69,
    cum_plan: 3808.4,
  },
  {
    year: "2023-10",
    act: 508.58,
    plan: 495.59,
    cum_act: 3832.27,
    cum_plan: 4304,
  },
  {
    year: "2023-11",
    act: 675.47,
    plan: 842.18,
    cum_act: 4507.74,
    cum_plan: 5146.18,
  },
  {
    year: "2023-12",
    act: 723.5,
    plan: 1533.66,
    cum_act: 5231.25,
    cum_plan: 6679.84,
  },
];

const kpidata1 = {
  202312: [
    {
      name: "서울",
      continent: "Project",
      setting: {
        fill: "#9ecae1",
      },
      value: 1477.14,
      x: 0.94594,
      y: 0.7146,
    },
    {
      name: "경기도",
      continent: "Project",
      setting: {
        fill: "#7392B7",
      },
      value: 3610.5,
      x: 0.7871,
      y: 0.8435,
    },
    {
      name: "강원도",
      continent: "Project",
      setting: {
        fill: "#A9D6E5",
      },
      value: 1477.14,
      x: 1.1,
      y: 1.3,
    },
    {
      name: "충청북도",
      continent: "Project",
      setting: {
        fill: "#6baed6",
      },
      value: 3610.5,
      x: 1.3,
      y: 0.5,
    },
    {
      name: "충청남도",
      continent: "Project",
      setting: {
        fill: "#014F86",
      },
      value: 200.14,
      x: 1.34594,
      y: 0.7146,
    },
    {
      name: "전라북도",
      continent: "Project",
      setting: {
        fill: "#89C2D9",
      },
      value: 1000.5,
      x: 0.7871,
      y: 1.435,
    },
    {
      name: "전라남도",
      continent: "Project",
      setting: {
        fill: "#A9D6E5",
      },
      value: 1477.14,
      x: 1.1,
      y: 1.3,
    },
    {
      name: "경상북도",
      continent: "Project",
      setting: {
        fill: "#BFDBF7",
      },
      value: 500.5,
      x: 0.3,
      y: 0.9,
    },
    {
      name: "경상남도",
      continent: "Project",
      setting: {
        fill: "#1F487E",
      },
      value: 1477.14,
      x: 0.7,
      y: 1.2,
    },
    {
      name: "제주도",
      continent: "Project",
      setting: {
        fill: "#97ADC8",
      },
      value: 2600.5,
      x: 0.5,
      y: 0.5,
    },
    // Add more projects for January 2022 if needed
  ],
  202202: [
    {
      name: "서울",
      continent: "Project",
      setting: {
        fill: "#9ecae1",
      },
      value: 1450.2,
      x: 0.9,
      y: 0.8,
    },
    {
      name: "경기도",
      continent: "Project",
      setting: {
        fill: "#7392B7",
      },
      value: 2610.3,
      x: 0.6,
      y: 0.7,
    },
    {
      name: "강원도",
      continent: "Project",
      setting: {
        fill: "#A9D6E5",
      },
      value: 2000.14,
      x: 1.1,
      y: 1.3,
    },
    {
      name: "충청북도",
      continent: "Project",
      setting: {
        fill: "#6baed6",
      },
      value: 1000.5,
      x: 1.3,
      y: 0.5,
    },
    {
      name: "충청남도",
      continent: "Project",
      setting: {
        fill: "#014F86",
      },
      value: 1477.14,
      x: 0.94594,
      y: 0.7146,
    },
    {
      name: "전라북도",
      continent: "Project",
      setting: {
        fill: "#89C2D9",
      },
      value: 3610.5,
      x: 0.7871,
      y: 0.8435,
    },
    {
      name: "전라남도",
      continent: "Project",
      setting: {
        fill: "#A9D6E5",
      },
      value: 1477.14,
      x: 1.1,
      y: 1.3,
    },
    {
      name: "경상북도",
      continent: "Project",
      setting: {
        fill: "#BFDBF7",
      },
      value: 3610.5,
      x: 1.3,
      y: 0.5,
    },
    {
      name: "경상남도",
      continent: "Project",
      setting: {
        fill: "#1F487E",
      },
      value: 1477.14,
      x: 1.1,
      y: 1.3,
    },
    {
      name: "제주도",
      continent: "Project",
      setting: {
        fill: "#97ADC8",
      },
      value: 3610.5,
      x: 1.3,
      y: 0.5,
    },
    // Add more projects for February 2022 if needed
  ],
  // Continue adding data for subsequent months
  // ...
};

const kpidata2 = {
  202312: [
    {
      name: "Apt 1",
      continent: "Project",
      setting: {
        fill: "#9ecae1",
      },
      value: 1477.14,
      x: 0.94594,
      y: 0.7146,
    },
    {
      name: "Apt 2",
      continent: "Project",
      setting: {
        fill: "#7392B7",
      },
      value: 3610.5,
      x: 0.7871,
      y: 0.8435,
    },
  ],
};
