import React from "react";

const TotalSafetyStatus1 = () => {
  return (
    <>
      <div
        style={{
          width: "45%",
          margin: "auto 0",
          textAlign: "center",
        }}
      >
        <p style={{ fontSize: "10px" }}>
          <span style={{ color: "orange" }}>특별위험작업</span> / 중점위험작업
        </p>
        <p style={{ fontSize: "30px" }}>
          <span style={{ color: "orange" }}>104</span>
          <span style={{ fontSize: "15px" }}> 개</span>
          <span> / 494</span>
          <span style={{ fontSize: "15px" }}> 개</span>
        </p>
      </div>
    </>
  );
};

export default TotalSafetyStatus1;
