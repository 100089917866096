import React from "react";
import { Milestones } from "react-milestones-vis";
const ProjectMilestone = ({ width, height }) => {
  const data = [
    {
      title: "Milestone 1",
      date: "2023-12-01",
      titleStyle: { color: "red" },
    },
    {
      title: "Milestone 2",
      date: "2023-12-04",
      titleStyle: { color: "green" },
    },
    {
      title: "Milestone 3",
      date: "2023-12-6",
    },
    {
      title: "Milestone 4",
      date: "2023-12-16",
    },
    {
      title: "Milestone 5",
      date: "2023-12-20",
    },
    // {
    //   title: "Vikings found Dublin in Ireland",
    //   year: 850,
    // },
    // {
    //   title: "Vikings found Dublin in Ireland",
    //   year: 860,
    // },
    // {
    //   title: "Vikings found Dublin in Ireland",
    //   year: 870,
    // },
  ];
  return (
    <div>
      <div
        className="card card-flush mb-5"
        style={{ width: width, height: height }}
      >
        <div className="card-header ">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Project MileStone</h3>
          </div>
        </div>
        <div className="card-body p-9">
          <Milestones
            aggregateBy="day"
            data={data}
            mapping={{
              text: "title",
              timestamp: "date",
            }}
            parseTime="%Y-%m-%d"
            optimize={true}
            autoResize={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectMilestone;
