import React from "react";

const ProjectCards = ({ width, height }) => {
  return (
    <div>
      <div
        className="card card-flush p-1 me-5 mb-5"
        style={{ minWidth: "300px", width: width, height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">인원 및 장비</h3>
          </div>
        </div>
        <div className="card-body p-1">
          <div
            className="d-flex flex-wrap mb-5"
            style={{ justifyContent: "center" }}
          >
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 me-5 ">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                일일 인원
              </div>
              <div className="fs-4 text-gray-800 fw-bold">34 명</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 ">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                누적 인원
              </div>
              <div className="fs-4 text-gray-800 fw-bold">12,519 명</div>
            </div>
          </div>
          <div
            className="d-flex flex-wrap mb-5"
            style={{ justifyContent: "center" }}
          >
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 me-5 ">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                일일 장비
              </div>
              <div className="fs-4 text-gray-800 fw-bold">9 대</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 ">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                누적 장비
              </div>
              <div className="fs-4 text-gray-800 fw-bold">2,024 대</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCards;
