import React from "react";

const ProgressStatus = () => {
  return (
    <>
      <div className="flex-grow-1 ">
        <p className="text-dark fw-bold fs-4">■ 9월 8일 기준 : 98.74%</p>
        <div className="py-3 ms-7">
          <p>- 8월 22일 상업운전개시</p>
          <br />
          <p>- 8월 31일 준공</p>
          <br />
          <p>- 예비품 납품 후 공정률 100% 예정</p>
        </div>
      </div>
    </>
  );
};

export default ProgressStatus;
