import React, { useState } from "react";
import Navigator2 from "../../../components/Navigator2";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import MyResponsivePie from "../../../components/nivoPieChart";
import SafetyBarChart from "./SafetyBarChart";
import SafetyBarChart2 from "./SafetyBarChart2";
import SafetyStatus from "./SafetyStatus";

const ApartSafety = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Navigator2
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        style={{
          //   display: "flex",
          padding: "40px 35px",
          width: isSidebarOpen ? width - 280 : width,
          height: height,
          margin: "auto",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0"> 유해위험 요인 발굴 참여 현황 </h3>
              </div>
            </div>
            <div className="card-body p-1">
              <div
                className="d-flex flex-wrap mb-5"
                style={{
                  justifyContent: "space-evenly",
                  textAlign: "center",
                }}
              >
                <div className="border border-gray-300 border-dashed rounded min-w-250px min-h-75px py-3 px-4 me-5 ">
                  <div className="fw-semibold text-gray-500 fw-bold ">
                    총 누적
                  </div>
                  <div className="fs-2 text-gray-800 fw-bold mt-2">52</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-250px min-h-75px py-3 px-4 ">
                  <div className="fw-semibold text-gray-500 fw-bold ">
                    12월 현장
                  </div>
                  <div className="fs-2 text-gray-800 fw-bold mt-2">0</div>
                </div>
              </div>
              <div
                className="d-flex flex-wrap mb-5"
                style={{ justifyContent: "space-evenly" }}
              >
                <div style={{ width: "50%", height: "170px" }}>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    위험유형별
                  </p>
                  <MyResponsivePie data={data} />
                </div>
                <div style={{ width: "50%", height: "170px" }}>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    12월 TOP 3
                  </p>
                  <SafetyBarChart2 />
                </div>
              </div>
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">재해 현황</h3>
              </div>
            </div>
            <div className="card-body p-1 scroll">
              <div
                className="d-flex flex-wrap"
                style={{ justifyContent: "space-evenly", textAlign: "center" }}
              >
                <div className="border border-gray-300 border-dashed rounded min-w-150px h-75px py-3 px-4 me-5 ">
                  <div className="fw-semibold text-gray-500 fw-bold ">
                    12월 현황
                  </div>
                  <div className="fs-2 text-gray-800 fw-bold mt-2">0</div>
                </div>
                <div style={{ width: "70%", height: "80px" }}>
                  <SafetyBarChart />
                </div>
              </div>
              <div
                className="d-flex flex-wrap mt-10"
                style={{ justifyContent: "space-evenly" }}
              >
                <div style={{ width: "40%", height: "170px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    현장누적
                  </p>
                  <MyResponsivePie />
                </div>
                <div style={{ width: "40%", height: "170px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    재해 유형
                  </p>
                  <MyResponsivePie />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 2
                : ((width - 80) / 5) * 2,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">안전교육 실시 현황</h3>
              </div>
            </div>
            <div className="card-body p-1">
              <div
                className="d-flex flex-wrap mb-5"
                style={{ justifyContent: "space-evenly", textAlign: "center" }}
              >
                <div className="border border-gray-300 border-dashed rounded min-w-250px min-h-75px py-3 px-4 me-5 ">
                  <div className="fw-semibold text-gray-500 fw-bold ">
                    안전교육 총 횟수
                  </div>
                  <div className="fs-2 text-gray-800 fw-bold mt-2">457 회</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-250px min-h-75px py-3 px-4 ">
                  <div className="fw-semibold text-gray-500 fw-bold ">
                    안전교육 총 인원
                  </div>
                  <div className="fs-2 text-gray-800 fw-bold mt-2">
                    1,850 명
                  </div>
                </div>
              </div>
              <div style={{ margin: "30px" }}>
                <h5 className="fw-bold mb-5">최근 안전교육 내용</h5>
                <div style={{ height: "200px", overflow: "scroll" }}>
                  <div style={{ overflow: "scroll" }}>
                    <table className="table table-row-dashed table-row-gray-300">
                      <thead
                        style={{
                          position: "sticky",
                          top: "0",
                          backgroundColor: "#fff",
                        }}
                      >
                        <tr className="fw-bolder fs-6 text-gray-800 ">
                          <th style={{ width: "20%" }}>교육일자</th>
                          <th style={{ width: "20%" }}>대상</th>
                          <th>내용</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2023-12-01 </td>
                          <td>보통인부</td>
                          <td>품질안전보건교육</td>
                        </tr>
                        <tr>
                          <td>2023-12-04 </td>
                          <td>굴착기운전원</td>
                          <td>굴착면의 높이가 2m 이상이 ...</td>
                        </tr>
                        <tr>
                          <td>2023-12-05 </td>
                          <td>보통인부</td>
                          <td>품질안전보건교육</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card card-flush me-5 mb-7"
            style={{
              width: isSidebarOpen
                ? ((width - 80 - 280) / 5) * 3
                : ((width - 80) / 5) * 3,
              height: (height - 100) / 2 - 10,
            }}
          >
            <div className="card-header">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">안전보건 관련 Issue 사항</h3>
              </div>
            </div>
            <div className="card-body p-9 mb-9 scroll">
              <SafetyStatus />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApartSafety;
const data = [
  {
    id: "낙하",
    label: "낙하",
    value: 4,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "전도",
    label: "전도",
    value: 5,
    color: "hsl(310, 70%, 50%)",
  },
  {
    id: "협착",
    label: "협착",
    value: 2,
    color: "hsl(176, 70%, 50%)",
  },
  {
    id: "충돌",
    label: "충돌",
    value: 7,
    color: "hsl(39, 70%, 50%)",
  },
  {
    id: "기타",
    label: "기타",
    value: 3,
    color: "hsl(68, 70%, 50%)",
  },
];
