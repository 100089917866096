import { ResponsivePie } from "@nivo/pie";
import styled from "styled-components";

// const data = [
//   {
//     id: "data1",
//     label: "data1",
//     value: 200,
//     color: "hsl(287, 70%, 50%)",
//   },
//   {
//     id: "data2",
//     label: "data2",
//     value: 300,
//     color: "hsl(310, 70%, 50%)",
//   },
//   {
//     id: "data3",
//     label: "data3",
//     value: 261,
//     color: "hsl(176, 70%, 50%)",
//   },
//   {
//     id: "data4",
//     label: "data4",
//     value: 497,
//     color: "hsl(39, 70%, 50%)",
//   },
//   {
//     id: "data5",
//     label: "data5",
//     value: 459,
//     color: "hsl(68, 70%, 50%)",
//   },
// ];
const MyResponsivePie = ({ data = pie_chart_base }) => {
  console.log(data);
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, right: 45, bottom: 40, left: 45 }}
      sortByValue={true}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", "0.2"]],
      }}
      // colors={["#EB7A25", "#F47560", "#F1E15B", "#61CDBB", "#E8C1A0"]}
      colors={{ scheme: "blues" }}
      // arcLinkLabel={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsOffset={-5}
      arcLinkLabelsDiagonalLength={10}
      arcLinkLabelsStraightLength={5}
      arcLabel={(e) => {
        const { data } = e;
        if (e.id === "") {
          return "N/A";
        }

        return data.value;
      }}
      arcLinkLabel={(e) => {
        if (e.id === "") {
          return "";
        }
        return e.id + `(${e.value})`;
      }}
      arcLinkLabelsThickness={data[0].id === "" ? 0 : 1}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={20}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      tooltip={(e) => {
        const { datum } = e;
        const { id, label, value, color } = datum;
        if (id === "") {
          return;
        }
        return (
          <NivoPieCustomTooltopBox>
            <NivoPieTooltipLabelColorBox label_color={color} />
            <NivoPieTooltipLabel>{label}:</NivoPieTooltipLabel>
            <NivoPieTooltipLabel>{value}</NivoPieTooltipLabel>
          </NivoPieCustomTooltopBox>
        );
      }}
    />
  );
};

export default MyResponsivePie;
const pie_chart_base = [
  {
    id: "",
    label: "",
    total: 0,
    percent: 0,
    value: 1,
    // color: "rgb(243, 115, 32)",
    color: "hsl(46, 70%, 50%)",
  },
];
const NivoPieCustomTooltopBox = styled.div`
  background-color: #fff;
  height: 30px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 5px 5px;
  border-radius: 5px;
`;
const NivoPieTooltipLabelColorBox = styled.div`
  margin-right: 5px;
  height: 10px;
  width: 10px;
  background-color: ${({ label_color }) => label_color};
`;
const NivoPieTooltipLabel = styled.div``;
