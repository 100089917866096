import React from "react";
import styled from "styled-components";

const TotalTable2 = () => {
  return (
    <div style={{ height: 150, overflow: "scroll", marginTop: "10px" }}>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          서울
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              개발행위허가(23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              사업지 선정 (23.11)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          경기도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              건축허가 승인 (23.10)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              사유지 보상협의 (23.12)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          강원도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              개발행위허가(23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>SPC 설립 (23.12)</BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          충청북도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              사업권 인수 계획 (23.09)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              개발제안서 시 제출(23.11)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          충청남도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              개발행위허가(23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              개발행위 재접수 (23.09)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          전라북도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              사유지 보상협의 (23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              환지계획 인가 (23.11)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          전라남도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              산업 90%, 공정 54% (23.10)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              사업권 인수 계획 (23.09)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          경상북도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              전기사업 허가 (23.03)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              산업 90%, 공정 54% (23.10)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          경상남도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              보상가격 개별통지 (23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              전기사업 허가 (23.03)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
      <ContentsBoxDiv>
        <ContentsSectorNameBoxDiv item_width={100} item_height={50}>
          제주도
        </ContentsSectorNameBoxDiv>
        <ContentsDetailBox>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt1</BulletChartName>
            <BulletChartText item_width={205}>
              개발행위허가(23.12)
            </BulletChartText>
          </BulletChartDiv>
          <BulletChartDiv>
            <BulletChartName item_width={155}>apt2</BulletChartName>
            <BulletChartText item_width={205}>
              전기사업 허가 (23.03)
            </BulletChartText>
          </BulletChartDiv>
        </ContentsDetailBox>
      </ContentsBoxDiv>
    </div>
  );
};

export default TotalTable2;
const ContentsBoxDiv = styled.div`
  display: flex;
  border-bottom: 1px solid lightgray;
`;

const ContentsSectorNameBoxDiv = styled.div`
  width: ${({ item_width }) => {
    return item_width;
  }}px;
  height: ${({ item_height }) => {
    return item_height;
  }}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  /* margin: auto;  */
`;

const ContentsDetailBox = styled.div`
  overflow-x: dden;
  overflow-y: scroll;
  height: 50px;
  /* height: ${({ item_height }) => {
    return item_height;
  }}px;
  width: ${({ item_width }) => {
    return item_width;
  }}px; */
`;
const BulletChartDiv = styled.div`
  display: flex;
  padding: 0px 0px;
  align-items: center;
  height: 25px;
`;
const BulletChartName = styled.div`
  width: ${({ item_width }) => {
    return item_width;
  }}px;
  padding-right: 5px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color: #fff; */
  text-align: left;
`;
const BulletChartText = styled.div`
  width: ${({ item_width }) => {
    return item_width;
  }}px;

  padding-left: 5px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ font_color }) => font_color};
`;
