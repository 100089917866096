import React from "react";

const ProejectCards2 = ({ width, height }) => {
  return (
    <div>
      <div
        className="card card-flush p-1 me-5 mb-5"
        style={{ minWidth: "300px", width: width, height: height }}
      >
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">품질활동</h3>
          </div>
        </div>
        <div className="card-body scroll p-5 pt-0">
          <div className=" flex-wrap " style={{ justifyContent: "center" }}>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 mb-5 ">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                불합격수 / 총 검사수
              </div>
              <div className="fs-4 text-gray-800 fw-bold x">0 건 / 3 건</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 mb-5">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                조치 부적합 / 전체 부적합
              </div>
              <div className="fs-4 text-gray-800 fw-bold ">0 건 / 4 건</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4 mb-5">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                조치 OP / 전체 OP
              </div>
              <div className="fs-4 text-gray-800 fw-bold ">4 건 / 13 건</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-150px min-h-75px py-3 px-4">
              <div className="fw-semibold text-gray-500 fw-bold mt-2">
                LESSONS LEARNED
              </div>
              <div className="fs-4 text-gray-800 fw-bold ">0 건</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProejectCards2;
