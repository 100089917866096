import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper/modules";
import Zoom from "react-medium-image-zoom";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "react-medium-image-zoom/dist/styles.css";
const ImageSwiper = ({ imageList, width, height }) => {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const handleImageSelect = (index) => {
    // 선택된 이미지의 인덱스를 상태에 저장
    setSelectedImageIndex(index);

    // 여기에서 필요한 다른 작업 수행
    // 예를 들어, 선택된 이미지의 다른 속성을 사용하여 테이블을 업데이트
  };
  console.log(selectedImageIndex);
  return (
    <ProjectPhotoDiv>
      <div style={{ display: "flex" }}>
        <div style={{ width: width / 4, marginRight: "5px" }}>
          <Swiper
            onSwiper={setImagesNavSlider}
            direction="vertical"
            spaceBetween={4}
            slidesPerView={4}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-container1"
            style={{ height: height, position: "relative" }}
            effect="fade"
          >
            {imageList.map((c, i) => {
              const { image } = c;
              return (
                <SwiperSlide key={i}>
                  <SlideImg
                    src={image}
                    alt="project_img"
                    onClick={(swiper) => setSelectedImageIndex(i)}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div style={{ width: (width / 4) * 3 }}>
          <Swiper
            thumbs={{ swiper: imagesNavSlider }}
            direction="horizontal"
            slidesPerView={1}
            navigation={true}
            spaceBetween={10}
            mousewheel={true}
            className="swiper-container2"
            modules={[Navigation, Thumbs, Mousewheel, FreeMode]}
            style={{
              height: height,
            }}
            effect="fade"
          >
            {imageList.map((c, i) => {
              const { image } = c;
              return (
                <SwiperSlide key={i}>
                  <SildeContentsBox>
                    <Zoom>
                      <SlideImg2
                        src={image}
                        alt="project_img"
                        tile_height={height}
                      />
                    </Zoom>
                  </SildeContentsBox>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div
        style={{
          height: ((height - 100) / 3 - 30 - 60) * 0.2,
          display: "flex",
          marginTop: "15px",
          overflow: "scroll",
          fonsize: "12px",
        }}
      >
        <table
          className="table table-row-dashed table-row-gray-300 pb-0 mb-0"
          border="1px solid"
        >
          <tbody>
            <tr>
              <td
                width="15%"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: 5,
                  paddingLeft: 5,
                }}
              >
                <b>이슈</b>
                {/* <div
                            className="vr"
                            style={{
                              marginLeft: "10px",
                              margin: 0,
                              padding: 0,
                            }}
                          ></div> */}
              </td>
              <td width="45%" style={{ margin: 0, padding: 0, paddingTop: 5 }}>
                {imageList[selectedImageIndex].issueTitle}
              </td>
              <td width="15%" style={{ margin: 0, padding: 0, paddingTop: 5 }}>
                <b>분류</b>
                {/* <div
                            className="vr"
                            style={{ marginLeft: "10px" }}
                          ></div> */}
              </td>
              <td width="25%" style={{ margin: 0, padding: 0, paddingTop: 5 }}>
                {imageList[selectedImageIndex].issueStatus}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ProjectPhotoDiv>
  );
};

export default ImageSwiper;
const SildeContentsBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
const ProjectPhotoDiv = styled.div`
  /* display: "flex"; */
  .swiper-container1 .swiper-slide {
    opacity: 0.4;
  }

  .swiper-container1 .swiper-slide:hover {
    opacity: 1 !important;
  }

  .swiper-container1 .swiper-slide-thumb-active {
    opacity: 1 !important;
  }
`;

const SlideImg = styled.img`
  height: 100%;

  width: 100%;

  object-fit: cover;
`;

const SlideImg2 = styled.img`
  height: ${({ tile_height }) => tile_height}px;
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
`;
