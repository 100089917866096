import { ResponsivePie } from "@nivo/pie";

const QualityNivoChart2 = ({ data, text, fontSize }) => {
  return (
    <>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
        innerRadius={0.7}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        colors={{ scheme: "blues" }}
        arcLabel={false}
        // arcLabel={(e) => {
        //   const { data } = e;
        //   if (e.id === "") {
        //     return "N/A";
        //   }

        //   return data.value;
        // }}
        arcLinkLabel={false}
        // arcLinkLabel={(e) => {
        //   if (e.id === "") {
        //     return "";
        //   }
        //   return e.id + `(${e.value})`;
        // }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={-5}
        arcLinkLabelsDiagonalLength={15}
        arcLinkLabelsStraightLength={10}
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        layers={[
          // 가운데 텍스트 레이어 추가
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          (props) => (
            <CustomCenterLabelLayer
              {...props}
              text={text}
              fontSize={fontSize}
            />
          ),
          "legends",
        ]}
        // legends={[
        //   {
        //     anchor: "bottom",
        //     direction: "row",
        //     justify: false,
        //     translateX: 0,
        //     translateY: 56,
        //     itemsSpacing: 0,
        //     itemWidth: 100,
        //     itemHeight: 18,
        //     itemTextColor: "#999",
        //     itemDirection: "left-to-right",
        //     itemOpacity: 1,
        //     symbolSize: 18,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </>
  );
};

export default QualityNivoChart2;
const CustomCenterLabelLayer = ({ centerX, centerY, text, fontSize }) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: fontSize,
        fontWeight: "bold",
        fill: "#333",
      }}
    >
      {text}
    </text>
  );
};
